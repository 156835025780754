import axios from 'axios';
import { BackgroundJob } from '../ca/BackGroundJobsDashboard';
import { getConfigurations } from '../Configurations';
import { AuthService } from './AuthService';
import { CertificateInfo, HolderInfo, AddCA, CANodeInfo, CertificatesInfo, CrewDbUser, BulkCertificateInfo, SearchCertificateCriteria, TrainingCenter, TokenInfo } from './ModelView';
import SecretStorage from './SecretCodeService';

// =======================================================================================================

export class NodeService {


    static token: string | null = null;
    static authService: AuthService;
    static GetTokenHeader() {
        return {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
        }
    }

    static setAuthService(auth: AuthService) {
        this.authService = auth;
    }
    // --------------------------------------------------------------------------------------------------
    // Governing Body
    // --------------------------------------------------------------------------------------------------
    /**
     * Add certificate authority to list of CA's and grant create certificate rights
     * POST: /ca/{address}/add
     * @param address 
     */
    public async gbAddCA(email: string, organization: string, name: string): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            var req: AddCA = { email: email, address: '', organization: organization, name: name }
            const response = await axios.post(conf.serverRoot + `ca/add`, req, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Grant create rights of certificate rights
     * POST: /ca/{address}/approve_create
     * @param address 
     */
    public async gbCaApproveCreate(address: string): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/${address}/rights`, null, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Revoke create rights of certificate rights
     * POST: /ca/{address}/revoke_create
     * @param address 
     */
    public async gbCaRevokeCreate(address: string): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/${address}/rights`, null, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getOrganizationStructure(): Promise<any[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + `ca/organization`, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    async getAddress(forcereload: boolean = false) {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + `address?forcereload=${forcereload}`, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Get the certificates of a specific certificate authority by address
     * GET: /ca/{address}/certificates
     * @param address 
     */
    public async gbGetCaCertificates(address: string, pageNumber: number, pageSize: number): Promise<CertificatesInfo> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + `ca/${address}/certificates?page=${pageNumber}&pageSize=${pageSize}`, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // --------------------------------------------------------------------------------------------------
    /**
     * filter the certificates of a specific certificate authority by address
     * GET: /ca/{address}/certificates
     * @param address 
     */
    public async caSearchCaCertificates(address: string, criteria: SearchCertificateCriteria, pageNumber: number, pageSize: number): Promise<any> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/${address}/searchcertificates?page=${pageNumber}&pageSize=${pageSize}`, criteria, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }



    // --------------------------------------------------------------------------------------------------
    /**
     * Get certificate authority addresses
     * GET: /ca
     */
    public async gbGetCa(): Promise<string[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + "ca", { headers: NodeService.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async gbGetCaInfo(): Promise<CANodeInfo[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + "ca", { headers: NodeService.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async addcaAddmim(email: string, name: string): Promise<any> {
        try {

            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/addadmin?email=${email}&name=${name}`, '', { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async setTrainingCenter(caAddress: string | undefined, trainingCenter: string): Promise<any> {
        try {

            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/${caAddress}/setTrainingCenter?id=${trainingCenter}`, '', { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Check if certificate authority has create rights
     * GET: /ca/{address}/rights   
     */
    public async gbGetCaHasCreateRights(address: string): Promise<boolean> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + `ca/${address}/rights`, { headers: NodeService.GetTokenHeader() });
            return response.data.result?.create;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Get the ether balance of a certificate authority
     * GET: /ca/{address}/balance
     */
    public async gbGetBalance(address: string): Promise<number> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(conf.serverRoot + `ca/${address}/balance`, { headers: NodeService.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Send ether to a certificate authority
     * POST: /ca/{address}/send_ether 
     */
    public async gbSendEther(address: string, amount: number): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `ca/${address}/send_ether`, amount, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // --------------------------------------------------------------------------------------------------
    /**
     * Get the certificates address of a holder by address
     * GET: ​/holder​/{address}​/certificates
     * @param address 
     */
    public async getHolderCertificates(address: string, page: Number, isGuest: boolean = false): Promise<CertificatesInfo> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(`${conf.serverRoot}holder/${address}/certificates?page=${page}&pageSize=10`, { headers: !isGuest ? NodeService.GetTokenHeader() : SecretStorage.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Get all registered users
     * GET: ​/holder​
     * @param address 
     */
    public async getHolders(): Promise<HolderInfo[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(`${conf.serverRoot}holder`, { headers: NodeService.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getDbHolders(search: string, page: number): Promise<CrewDbUser[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(`${conf.serverRoot}holders?search=${search}&page=${page}`, { headers: NodeService.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    // --------------------------------------------------------------------------------------------------
    /**
     * Get holder by email
     * GET: ​/holder​
     * @param address 
     */
    public async getHolder(email: string, isGuest: boolean = false): Promise<HolderInfo[]> {
        try {
            const conf = getConfigurations();
            var customHeaders = SecretStorage.GetTokenHeader();
            const response = await axios.get(`${conf.serverRoot}holder/by_email?email=${email.trim()}`, { headers: !isGuest ? NodeService.GetTokenHeader() : customHeaders });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Get holder by crewId
     * GET: ​/holder​
     * @param address 
     */
    public async getHolderByCrewId(crewId: string, isGuest: boolean = false): Promise<HolderInfo[]> {
        try {
            const conf = getConfigurations();
            const response = await axios.get(`${conf.serverRoot}holder/by_crewId?crewId=${crewId.trim()}`, { headers: !isGuest ? NodeService.GetTokenHeader() : SecretStorage.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // --------------------------------------------------------------------------------------------------
    /**
     * Get holders by name
     * GET: ​/holder​
     * @param address 
     */
    public async getName(name: string, isGuest: boolean = false) {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}holder/by_names?name=${name.trim()}`, { headers: !isGuest ? NodeService.GetTokenHeader() : SecretStorage.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Create a new holder
     * POST: ​/holder/register
     * @param address 
     */
    public async registerHolder(name: string, surname: string, email: string, date_of_birth: string): Promise<{
        code: number,
        message: HolderInfo | string
    }> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `holder/register`,
                { name, surname, email, date_of_birth },
                { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Get the certificates address of a holder by address
     * GET: ​/holder​/{address}​/certificates
     * @param address 
     */
    public async getHolderChallenge(address: string, isGuest: boolean = false): Promise<string> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(`${conf.serverRoot}holder/${address}/challenge`, "", { headers: !isGuest ? NodeService.GetTokenHeader() : SecretStorage.GetTokenHeader() });
            return response.data.result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    /**
     * Revoke a certificate
     * POST: /certificate/{address}/revoke
     * @param address 
     */
    public async revokeCertificate(address: string): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            const response = await axios.post(`${conf.serverRoot}certificate/${address}/revoke`, null, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------
    // Certificate Authority
    // --------------------------------------------------------------------------------------------------
    /**
     * Create a new certificate, if successful returns the address
     * POST: /certificate/create
     * @param address 
     */
    public async caCreateCertificate(certificate: CertificateInfo, seafarers: CrewDbUser[]): Promise<{ code: number, message: any }> {
        try {
            const conf = getConfigurations();
            var bulkInfo: BulkCertificateInfo =
            {
                certificate: certificate,
                holders: seafarers
            }
            const response = await axios.post(conf.serverRoot + "certificate/bulkissuance", bulkInfo, { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // --------------------------------------------------------------------------------------------------

    public async getJobs(): Promise<Array<BackgroundJob>> {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}backgroundjobs`, { headers: NodeService.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return [];
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async getJob(jobId: string): Promise<BackgroundJob | undefined> {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}backgroundjob/${jobId}`, { headers: NodeService.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async getOneTimeTokens(): Promise<[] | undefined> {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}fleetmgmt/getTokens`, { headers: NodeService.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async issueOneTimeToken(formValue: TokenInfo): Promise<boolean> {
        try {
            const conf = getConfigurations();
            var resp = await axios.post(`${conf.serverRoot}fleetmgmt/issueOneTimeToken?issuedTo=${formValue.issuedTo}&email=${formValue.email}&phone=${formValue.phone}&validDate=${formValue.validDate?.toDateString()}`, formValue, { headers: NodeService.GetTokenHeader() })
            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async revokeToken(id: number): Promise<boolean> {
        try {
            const conf = getConfigurations();
            var resp = await axios.post(`${conf.serverRoot}fleetmgmt/revokeToken?id=${id}`, '', { headers: NodeService.GetTokenHeader() })
            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async addOfficer(email: string, name: string): Promise<any> {
        try {

            const conf = getConfigurations();
            const response = await axios.post(conf.serverRoot + `fleetmgmt/addInternalOfficer?email=${email}&name=${name}`, '', { headers: NodeService.GetTokenHeader() });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async getOneTimeToken(secret: string): Promise<any> {
        try {

            var customHeaders = {
                "Api-Key": "gdhgehjhfu876fejfkek_9+GH",
                "Accept": "application/json",
                "secret": `${secret}`
            };
            const conf = getConfigurations();
            var resp = await axios.post(`${conf.serverRoot}CrewingOfficer/GetAccessToken`, "", { headers: customHeaders })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }


    // --------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------
    //crewman requests
    public async getTrainings() {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}crewman/trainings`, { headers: NodeService.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async getSesssions(centerId: string | undefined, trainingId: string, dateFrom: string, dateTo: string) {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}crewman/trainingSessions?centerid=${centerId}&trainingId=${trainingId}&dateFrom=${dateFrom}&dateTo=${dateTo}`, { headers: NodeService.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return;
        }
    }
    // --------------------------------------------------------------------------------------------------

    public async gettrainingCenters(): Promise<Array<TrainingCenter>> {
        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}crewman/trainingCenters`, { headers: NodeService.GetTokenHeader() })
            return resp.data.result;
        }
        catch (error) {
            console.error(error);
            return [];
        }
    }

    // --------------------------------------------------------------------------------------------------

    public async getCurriculumInfo(type: string | undefined, isGuest: boolean = false) {

        try {
            const conf = getConfigurations();
            var resp = await axios.get(`${conf.serverRoot}crewman/GetCurriculum?type=${type}`, { headers: !isGuest ? NodeService.GetTokenHeader() : SecretStorage.GetTokenHeader() })
            return resp.data;
        }
        catch (error) {
            console.error(error);
            return;
        }
    }
    // --------------------------------------------------------------------------------------------------

}
