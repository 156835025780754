import axios from "axios";
import jwt_decode from "jwt-decode";
import { getConfigurations } from "../Configurations";

export default class SecretStorage {

    static setUser(token: string, uname: string): void {
        localStorage.removeItem("nacreaccessToken");
        localStorage.setItem('nacreaccessToken', token);
        localStorage.removeItem("nacreuserName");
        localStorage.setItem("nacreuserName", uname);
        localStorage.removeItem("nacreLastVerified");
        localStorage.setItem("nacreLastVerified", new Date().toISOString())
    }

    static getAccessToken(): string | null {
        try {
            var value = localStorage.getItem("nacreaccessToken");
            if (value !== null) {
                var decoded = jwt_decode(value) as any;
                var expTicks = decoded.exp;
                var expired = new Date(0);
                expired.setUTCSeconds(expTicks);
                var now = new Date();
                if (expTicks < now.getTime() / 1000) {
                    this.logout();
                    return null;
                }
            }
            return value;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }

    static getUname(): string | null {
        try {
            var value = localStorage.getItem("nacreuserName");
            return value;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }

    static logout(): void {
        localStorage.removeItem("nacreaccessToken");
        localStorage.removeItem("nacreuserName");
        localStorage.removeItem("nacreLastVerified")
    }

    static async isAuthorized(): Promise<boolean> {
        try {
            var conf = getConfigurations();
            await axios.post(`${conf.serverRoot}crewingofficer/validateAccessToken`,"", { headers: SecretStorage.GetTokenHeader() });
            return true;
        }
        catch (error) {

            console.error("Access Token is not valid");
            return false;
        }
    }

    static GetTokenHeader() {
        return {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.getAccessToken(),
        }
    }

}