import React from 'react';
import * as toastr from 'toastr';
import logo from '../logo.svg';
import mscLogo from '../img/weblogo@2x.png';

import {
    Container, Header, Content, Footer,
    Nav, Navbar, Icon, Dropdown, IconButton
} from 'rsuite';


import CertificateAuthority from './CertificateAuthority';
import { AuthService } from '../services/AuthService';
import { NodeService } from '../services/NodeService';
import BackgroundJobsDashboard from './BackGroundJobsDashboard';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import SentryRRWeb from "@sentry/rrweb";
import { getConfigurations } from '../Configurations';
import { Roles } from '../services/ModelView';


// =======================================================================================================
export interface AppProps {
    width: number;
    height: number;
}
// ------------------------------------------------------------------------------------------------------

export interface UserNotification {
    id: string,
    description: string;
}
// ------------------------------------------------------------------------------------------------------
export interface AppState {
    activeKey: AppPagesKeys;

    // User info
    user: any;
    userName: string | undefined;
    role: string | undefined;
    notifications: UserNotification[]
}


// ------------------------------------------------------------------------------------------------------
enum AppPagesKeys {
    BJobs = "BackGround Jobs",
    CA = "ca",
}

if (process.env.APP_CONF === "release") {
    Sentry.init({
        dsn: "https://0bdba2dbf3f9407985f4cd83b84ae563@sentry.electi.dev/3",
        integrations: [
            new Integrations.BrowserTracing({ tracingOrigins: ['localhost', /^\//] }),
            new SentryRRWeb()
        ],
        release: "pre-rel.1.0",
        tracesSampleRate: 0.4,
        debug: false

    });

    Sentry.setTag("rrwebactive", "yes");
    console.log("App started in Release Mode");
}
else {
    console.log("App started in Dev Mode");
}


// ------------------------------------------------------------------------------------------------------
export default class App extends React.Component<AppProps, AppState> {
    // --------------------------------------------------------------------------------------------------
    public authService: AuthService;
    constructor(props: AppProps) {

        super(props);

        this.state = {
            activeKey: AppPagesKeys.CA,
            user: null,
            userName: undefined,
            role: undefined,
            notifications: []
        };
        this.authService = new AuthService();
        NodeService.setAuthService(this.authService);

    }
    // --------------------------------------------------------------------------------------------------
    public async componentDidMount() {
        await this.getUser();
    }
    // --------------------------------------------------------------------------------------------------

    public async viewBackGroundTasks() {
        try {

            this.setState({ activeKey: AppPagesKeys.BJobs });
        } catch (ex) {
            console.error(ex);

        }
    }
    // --------------------------------------------------------------------------------------------------
    public async viewHome() {
        this.setState({ activeKey: AppPagesKeys.CA });
    }
    // --------------------------------------------------------------------------------------------------
    public logout = () => this.authService.logout()
    // --------------------------------------------------------------------------------------------------

    public getUser = async () => {
        var user = await this.authService.getUser();
        if (user) {
            if (!user.profile?.role?.includes(Roles.CAAdmin.toString()) && !user.profile?.role?.includes(Roles.CAOWner.toString())) {
                alert('You do not have permissions.');
                return;
            }
            if (!user.expired) {
                this.authService.currentUser = user;
                console.log(user.access_token);
            }
        } else {
            toastr.error('You are not logged in.');

            // Start login process
            this.authService.login();
        }
        let role_s = user?.profile?.role! as string;
        this.setState({ user: user, userName: user?.profile.name, role: role_s })
    };
    // --------------------------------------------------------------------------------------------------


    getDescriptiveRole(role: string) {
        if (role === "Admin")
            return "GB Administrator";
        else if (role === "CAOwner")
            return "CA owner";
        else if (role === "CAUser")
            return "CA user";
    }
    // --------------------------------------------------------------------------------------------------
    renderDummyPage(text: string) {
        return <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p> CA {text} </p>
            </header>
        </div>
    }
    // --------------------------------------------------------------------------------------------------
    renderPage() {


        if (this.state.user == null)
            return null;

        switch (this.state.activeKey) {
            case AppPagesKeys.BJobs:
                return <BackgroundJobsDashboard height={this.props.height - 56 /* toolbar */ - 20 /* footer */} />;

            case AppPagesKeys.CA:
                return <CertificateAuthority role={this.state.role!} height={this.props.height - 56 /* toolbar */ - 20 /* footer */} />;

        }
    }
    // --------------------------------------------------------------------------------------------------
    handleSelect = (eventKey: any) => this.setState({ activeKey: eventKey })
    handleLogoutMenu = (eventKey: any, event: any) => this.authService.logout()
    // --------------------------------------------------------------------------------------------------
    render() {
        var title = "Certificate Authority";
        return <Container style={{ height: this.props.height, width: this.props.width }}>
            <Container>
                <Header>
                    <Navbar appearance="inverse" className="app-header">
                        <Navbar.Header>
                            <img src={mscLogo} className="msc-logo" alt="msc-logo" />
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect}>
                                {/*<Nav.Item eventKey={AppPagesKeys.Validation} icon={<Icon icon="address-book" />}>Validation</Nav.Item>*/}
                                <Nav.Item eventKey={AppPagesKeys.CA} icon={<Icon icon="certificate" />}>{title}</Nav.Item>
                            </Nav>
                            <Nav pullRight>
                                <Dropdown placement="bottomEnd" icon={<Icon icon="user-circle" />} trigger={["click", "hover"]} tile={this.state.userName}>
                                    <Dropdown.Item icon={<Icon icon="profile" />} style={{ borderRadius: 0 }}>{this.state.userName} - {this.getDescriptiveRole(this.state.role!)}</Dropdown.Item>
                                    <Dropdown.Item onSelect={this.handleLogoutMenu} icon={<Icon icon="exit" />} style={{ borderRadius: 0 }}>LogOut</Dropdown.Item>
                                </Dropdown>
                                {/*<Nav.Item >{this.state.userName}</Nav.Item>
                                <Nav.Item >{roles[this.state.role!]}</Nav.Item>*/}

                                {this.state.activeKey === AppPagesKeys.CA ? <Nav.Item icon={<IconButton style={{ marginTop: -5, background: "transparent", backgroundColor: "#fecb00" }} onClick={(c) => this.viewBackGroundTasks()} icon={<Icon icon="tasks" style={{ background: "transparent" }} />}>Tasks </IconButton>} />
                                    : <Nav.Item icon={<IconButton style={{ marginTop: -5, background: "transparent", backgroundColor: "#fecb00" }} onClick={(c) => this.viewHome()} icon={<Icon icon="home" style={{ background: "transparent" }} />} > Home </IconButton>} />}
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                </Header>
                <Content className="app-body">
                    {this.renderPage()}
                </Content>
                <Footer>©  2020 Electi, AI, Blockchain, Cryptography. </Footer>
            </Container>
        </Container>
    }
}
// =======================================================================================================
