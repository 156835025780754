import React from 'react';
import _ from 'lodash';
import { Icon, IconButton, Modal, Nav, Navbar, Panel, Radio, Table, Timeline } from 'rsuite';
import { NodeService } from '../services/NodeService';
import { BackgroundJobLiveView } from './BackgroundJobLiveView';
import { CertificateInfo, SearchCertificateCriteria } from '../services/ModelView';
import BatchDocuments from './BatchDocuments';




export interface BackgroundJob {
  jobId: string;
  jobType: string;
  progress: number,
  status: string;
  message: string;
  logs: Array<string>;
  createdAt: string;
  metadata: string;
}

export enum BackgroundJobType {
  None = "None",
  RegisterUsers = "RegisterUsers",
  CertificateIssuance = "CertificateIssuance"
}
// =======================================================================================================
export interface BackgroundJobsProps {
  height: number;
}
// ------------------------------------------------------------------------------------------------------
export interface BackgroundJobsState {
  ca: string;
  selectedJob: BackgroundJob | undefined;
  jobs: Array<BackgroundJob>
  showModal: boolean;
  loading: boolean;
  sessionCertificates: CertificateInfo[] | null | undefined;
}
// ------------------------------------------------------------------------------------------------------
export default class BackgroundJobsDashboard extends React.Component<BackgroundJobsProps, BackgroundJobsState> {
  private NodeService: NodeService;
  constructor(props: BackgroundJobsProps) {
    super(props);
    this.state =
    {
      ca: '',
      selectedJob: undefined,
      jobs: [],
      showModal: false,
      sessionCertificates: null,
      loading: false

    }
    this.NodeService = new NodeService()
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let cainfo = await this.NodeService.getAddress();
    var jobs = await this.getJobs();
    this.setState({ ca: cainfo?.publicKey, jobs: jobs, loading: false });
  }

  getJobs = async () => {
    try {
      var jobs = await this.NodeService.getJobs();
      return jobs;
    }
    catch (error) {
      return [];
    }
  }

  updateJobs = async () => {
    this.setState({ loading: true });
    var jobs = await this.getJobs();
    this.setState({ jobs: jobs, loading: false });
  }


  changeJobStatus = (status: string) => {
    var jobs = this.state.jobs;
    var selectedJob = jobs.find(job => job.jobId === this.state.selectedJob?.jobId);
    if (selectedJob)
      selectedJob.status = status;
    this.setState({ selectedJob: selectedJob, jobs: jobs })
  }


  handleSelectedJob = (job: BackgroundJob, value: any, checked: boolean, event: any) => {
    this.setState({ selectedJob: job })
  }

  renderJobs() {
    return <>
      <Navbar appearance={"default"}>
        <Navbar.Header>
          <div style={{
            lineHeight: "56px",
            paddingLeft: 20,
            fontSize: "120%",
            fontWeight: "bolder"
          }}>Tasks</div>
        </Navbar.Header>
        <Navbar.Body>
          <Nav pullRight >
            <Nav.Item pullRight icon={<Icon style={{ fontSize: 18 }} icon="refresh" />} onClick={this.updateJobs} />
          </Nav>
        </Navbar.Body>
      </Navbar>
      <Table data={this.state.jobs} height={this.props.height - 100} rowHeight={46}>
        <Table.Column align="center" width={70} fixed >
          <Table.HeaderCell></Table.HeaderCell>
          <Table.Cell>
            {(rowData: BackgroundJob) => {
              return (
                <Radio
                  style={{ marginTop: -8 }}
                  inline
                  checked={this.state.selectedJob?.jobId === null ? false : rowData.jobId === this.state.selectedJob?.jobId}
                  onChange={(v, c, e) => this.handleSelectedJob(rowData, v, c, e)}
                />
              );
            }}

          </Table.Cell>
        </Table.Column>
        <Table.Column width={70} align="center" fixed >
          <Table.HeaderCell>Task Id</Table.HeaderCell>
          <Table.Cell dataKey="jobId" />
        </Table.Column>

        <Table.Column width={270} align="center" >
          <Table.HeaderCell>Task Type</Table.HeaderCell>
          <Table.Cell dataKey="jobType" />
        </Table.Column>

        <Table.Column width={270} align="center" >
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey="status" />
        </Table.Column>

        <Table.Column width={100} align="center" >
          <Table.HeaderCell>Session Id</Table.HeaderCell>
          <Table.Cell dataKey="metadata" />
        </Table.Column>

        <Table.Column width={70} align="center"  >
          <Table.HeaderCell></Table.HeaderCell>
          <Table.Cell>
            {(rowData: BackgroundJob) => {
              return (
                <div>
                  { rowData.jobType === BackgroundJobType.CertificateIssuance.toString() ?
                    <IconButton style={{ marginTop: -5 }} icon={<Icon icon="print" />} onClick={(c) => this.printCerts(rowData.metadata)} /> : null
                  }
                </div>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={270} align="center" >
          <Table.HeaderCell>Created at</Table.HeaderCell>
          <Table.Cell dataKey="createdAt" />
        </Table.Column>
      </Table>
    </>
  }
  // --------------------------------------------------------------------------------------------------

  printCerts = async (sessionId: string) => {
    var criteria: SearchCertificateCriteria =
    {
      email: "",
      fromDate: undefined,
      toDate: undefined,
      name: "",
      sessionId: sessionId,
      crewId: '',
      certType: ''
    }
    var certificatesResp = await this.NodeService.caSearchCaCertificates(this.state.ca, criteria, 1, 1000);
    if (certificatesResp.result !== null && certificatesResp !== undefined)
      this.setState({ showModal: true, sessionCertificates: certificatesResp.result })
  }
  // --------------------------------------------------------------------------------------------------
  render() {
    return <div style={{ display: "flex" }} className="ca">
      <Panel shaded bodyFill style={{ flex: 1, height: this.props.height - 20, margin: 10, overflow: "auto" }}>
        {this.renderJobs()}
      </Panel>

      <Panel shaded bodyFill style={{ flex: 1, margin: 10, height: this.props.height - 20, overflow: "scroll" }} >
        {this.state.selectedJob === undefined ? null : <BackgroundJobLiveView onCompleted={(s) => this.changeJobStatus(s)} jobId={this.state.selectedJob?.jobId} />}

      </Panel>

      {this.state.showModal ? <div className="modal-container" style={{ height: this.props.height - 150 }}>

        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header>
            <Modal.Title>Session Certificates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.sessionCertificates !== null && this.state.sessionCertificates !== undefined && this.state.sessionCertificates.length > 0 ? <BatchDocuments certificates={this.state.sessionCertificates} /> : <div>Nothing to print </div>}


          </Modal.Body>

        </Modal>
      </div> : null}
    </div>
  }
}
// =======================================================================================================