import React, { SyntheticEvent } from 'react';
import _ from 'lodash';

import {
    Table, Radio, Placeholder, Panel, Navbar,
    Nav, Form, FormGroup, ControlLabel, FormControl,
    Icon, Modal, Button, Loader, IconButton, Checkbox, Uploader, Pagination, List, HelpBlock, SelectPicker,
} from 'rsuite';

import './CertificateAuthority.less';
import { NodeService } from '../services/NodeService';
import { CertificateInfo, CANodeInfo, HolderInfo, Roles, CertificatesInfo, CurriculumSummary, TrainingCenterMV, TrainingCenter, SearchCertificateCriteria } from '../services/ModelView';
import { getConfigurations } from '../Configurations';
import HolderCertificates from '../lib/HolderCertificates';
import { Certificate } from '../lib/Certificates';
import { AxiosError } from 'axios';
import { SearchCertificate } from '../lib/SearchCertificate';
import { AddCertificateForm } from './AddCertificateForm';
import { generateQR } from '../lib/Certificates';
import { CrewmanService } from '../services/CrewmanService';
import * as toastr from 'toastr';
import revokeIcon from '../img/revoke_icon.png';


// =======================================================================================================
interface RadioCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    selectedKey: string | null;
    dataKey: string
}
const RadioCell = (props: RadioCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            <Radio
                value={props.rowData[props.dataKey]}
                inline
                onChange={props.onChange}
                checked={props.selectedKey === props.rowData[props.dataKey]}
            />
        </div>
    </Table.Cell>
}


interface DisabledCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    dataKey: string
}

const DisabledCell = (props: DisabledCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            {
                (props.rowData[props.dataKey]) ?
                    <Icon
                        style={{ color: 'red' }}
                        icon='minus-circle'
                    /> :
                    null
            }
        </div>
    </Table.Cell>
}

// ------------------------------------------------------------------------------------------------------
export enum CAModalModes {
    Processing,
    Success,
    Error,
    AddCertificate,
    CertRevoke,
    Holder,
    SelectHolder,
    UploadHolders,
    AddCAAdmin,
    SearchCertificates,
    SetTrainingCenter
}
export interface ModalError {
    message: string
}
export interface CAAdminUser {
    name: string;
    email: string;
}
export type ModalData = Partial<CertificateInfo> | ModalError | undefined | HolderInfo[] | HolderInfo;
// ------------------------------------------------------------------------------------------------------
export interface CertificateAuthorityProps {
    height: number;
    role: string
}
// ------------------------------------------------------------------------------------------------------
export interface CertificateAuthorityState {
    trainingCenters: Array<TrainingCenterMV>

    caAddress: string | undefined;
    organization: string | undefined;
    trainingCenter: string | undefined;
    selectedCertificateKey: string | null;
    selectedCertificateInfo: Partial<CertificateInfo> | undefined;
    selectedCertificateImage: string | undefined;
    selectedCertificateCurriculum: CurriculumSummary | undefined;
    CANodeInfo: CANodeInfo | undefined;
    searchCertificateKey: string | null;
    sortNodeCertsColumn: string | undefined;
    sortNodeCertsType: "desc" | "asc" | undefined;

    showModal?: CAModalModes;
    modalBackdrop: 'static' | true | false;
    modalData?: ModalData;
    addCertificateErrorMessage?: string;

    uploadInProgress: boolean;
    uploadSuccess: boolean | undefined;

    activePage: number;
    totalCertificates: number;

    caadminUser?: CAAdminUser;
    addCaAdminEnabled: boolean
    caUsers?: any[]

    setCATrainingCenterEnabled: boolean;


    sortSelectHolderColumn: string | undefined;
    sortSelectHolderType: "desc" | "asc" | undefined;

    loading: boolean;

    error: string | undefined,
    criteria: SearchCertificateCriteria | undefined

}
// ------------------------------------------------------------------------------------------------------
export default class CertificateAuthority extends React.Component<CertificateAuthorityProps, CertificateAuthorityState> {
    private NodeService: NodeService;
    constructor(props: CertificateAuthorityProps) {
        super(props);

        this.state = {
            criteria: undefined,
            selectedCertificateKey: null,
            selectedCertificateInfo: undefined,
            selectedCertificateImage: undefined,
            selectedCertificateCurriculum: undefined,
            CANodeInfo: undefined,
            searchCertificateKey: null,
            sortNodeCertsColumn: undefined,
            sortNodeCertsType: undefined,
            showModal: undefined,
            modalData: undefined,
            modalBackdrop: 'static',
            uploadInProgress: false,
            uploadSuccess: undefined,
            caAddress: '',
            organization: '',
            totalCertificates: 0,
            activePage: 1,
            addCaAdminEnabled: true,
            sortSelectHolderColumn: undefined,
            sortSelectHolderType: undefined,
            loading: false,
            error: undefined,
            trainingCenter: undefined,
            trainingCenters: [],
            setCATrainingCenterEnabled: true


        };
        this.NodeService = new NodeService();
    }
    // --------------------------------------------------------------------------------------------------
    async getData(page: number) {
        var caAddress = this.state.caAddress!;
        this.setState({ loading: true });
        let caInfo: CANodeInfo = {
            ca: caAddress,
            name: this.state.organization!,
            certs: [],
            certsLength: 0,
            createRights: false,
            balance: -1
        };

        // Get the list with certificates
        try {
            let info = await this.NodeService.gbGetCaCertificates(caAddress, page, 10);
            this.setState({ totalCertificates: info.totalCount, loading: false });
            caInfo.certs = info.result;
            caInfo.certsLength = caInfo.certs.length;

        } catch (ex) {
            console.error(ex);
            this.setState({ loading: false })
        }

        try {
            caInfo.createRights = await this.NodeService.gbGetCaHasCreateRights(caAddress);
        } catch (ex) { console.error(ex) }

        this.setState({ CANodeInfo: caInfo });
    }

    // --------------------------------------------------------------------------------------------------
    onSortSelectHoldersColumn = (sortColumn: string, sortType: "desc" | "asc") => {
        this.setState((s: CertificateAuthorityState) => {
            s.sortSelectHolderColumn = sortColumn;
            s.sortSelectHolderType = sortType;
            s.modalData = _.orderBy(s.modalData, x => _.get(x, sortColumn), sortType);
            return s;
        })
    }
    // --------------------------------------------------------------------------------------------------

    selectTrainingCenter = (event: TrainingCenter) => {

        this.setState({ trainingCenter: event.centerId })
    }

    // --------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this.setState({ showModal: CAModalModes.Processing })
        let cainfo = await this.NodeService.getAddress();
        var mvs = new Array<TrainingCenterMV>();
        if (cainfo == null || cainfo == undefined || cainfo.trainingCenter == null || cainfo.trainingCenter == undefined || cainfo.trainingCenter.length == 0) {
            let centers = await this.NodeService.gettrainingCenters();

            if (centers !== null && centers !== undefined)
                centers.forEach((element: TrainingCenter) => {
                    var mv: TrainingCenterMV =
                    {
                        label: element.centerName,
                        value: element
                    }
                    mvs.push(mv)
                });
        }
        var users = new Array<any>()
        if (this.props.role === Roles.CAOWner.toString()) {
            users = await this.NodeService.getOrganizationStructure();
        }

        this.setState({ trainingCenters: mvs, caAddress: cainfo.publicKey, organization: cainfo.organization, trainingCenter: cainfo.trainingCenter, caUsers: users }, (async () => {
            await this.getData(1);
            var hasCenter = (this.state.trainingCenter !== null && this.state.trainingCenter !== undefined && this.state.trainingCenter.length > 0) ? undefined : CAModalModes.SetTrainingCenter;
            this.setState({ showModal: hasCenter })
        }))
    }
    // --------------------------------------------------------------------------------------------------
    handleSearchCertificateChange = (value: string, event: SyntheticEvent<HTMLElement, Event>) => this.setState({ searchCertificateKey: value })
    // --------------------------------------------------------------------------------------------------
    handleCertificateRowSelect = async (value: any, checked: boolean) => {

        var qrSignature = await generateQR(value);
        var certInfo = _.find(this.state.CANodeInfo?.certs, x => x.address === value)
        var lines = await CrewmanService.getCertificateInfo(certInfo?.type)
        this.setState({
            selectedCertificateImage: qrSignature,
            selectedCertificateKey: value,
            selectedCertificateInfo: certInfo,
            selectedCertificateCurriculum: lines

        });
    }
    // --------------------------------------------------------------------------------------------------
    onSortCertsColumn = (sortColumn: string, sortType: "desc" | "asc") => {
        this.setState((s: CertificateAuthorityState) => {
            if (s.CANodeInfo != null) {
                s.sortNodeCertsColumn = sortColumn;
                s.sortNodeCertsType = sortType;
                s.CANodeInfo.certs = _.orderBy(this.state.CANodeInfo?.certs, x => _.get(x, sortColumn), sortType);
            }
            return s;
        })
    }

    // --------------------------------------------------------------------------------------------------
    renderCertificatePanel() {
        if (
            this.state.selectedCertificateKey == null ||
            this.state.selectedCertificateInfo == null)
            return <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
            </Navbar>

        return <>
            <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        {
                            (this.state.selectedCertificateInfo.revoked || !this.state.CANodeInfo?.createRights) ? null :
                                <Nav.Item icon={<img src={revokeIcon} style={{ width: 25, height: 25 }} />} onClick={() => this.setState({ showModal: CAModalModes.CertRevoke })}> Revoke</Nav.Item>
                        }
                    </Nav>
                </Navbar.Body>
            </Navbar>
            <div style={{ width: "100%", height: this.props.height - 56 - 20, overflow: "auto" }}>
                <Certificate
                    width="100%"
                    height={this.props.height - 56 - 20}
                    certInfo={this.state.selectedCertificateInfo}
                    img={this.state.selectedCertificateImage}
                    curriculumSummary={this.state.selectedCertificateCurriculum}
                    showHolderInfo={() => this.setState({ showModal: CAModalModes.Holder })}
                    requestHolderChallenge={() => { console.log("Verification not supported by CA"); }}
                />
            </div>
        </>
    }

    // ------------------------------------------------------------------------------------------------------

    handleUpload = () => {
        this.setState({ uploadInProgress: true })
    }
    // --------------------------------------------------------------------------------------------------

    handleSelect = async (eventKey: number) => {
        this.setState({ activePage: eventKey, showModal: CAModalModes.Processing })
        if (this.state.criteria === undefined)
            await this.getData(eventKey);
        else
            await this.handleSearchCertificatesResult(this.state.criteria, eventKey, false)
        this.setState({ showModal: undefined })
    }

    // --------------------------------------------------------------------------------------------------

    complexSearch = () => {
        this.setState({ showModal: CAModalModes.SearchCertificates })
    }
    // --------------------------------------------------------------------------------------------------
    renderCAPanel() {
        if (
            this.state.CANodeInfo == null)
            return <Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate Authority</div>
                </Navbar.Header>
            </Navbar>;

        // In loading showing placer for animation waiting
        if (this.state.CANodeInfo == null)
            return <Panel bordered style={{ padding: 10, margin: 20, background: "white" }}>
                <Placeholder.Paragraph style={{ margin: 30 }} rows={5} graph="image" active />
            </Panel >

        // Filter certificated searching title/holder
        let data = this.state.CANodeInfo.certs;
        if (this.state.searchCertificateKey != null) {
            let searchStr = new RegExp(this.state.searchCertificateKey, 'i');
            data = _.filter(data, d => {
                if ((d.title?.search(searchStr) ?? -1) > -1)
                    return true;
                if ((d.holder?.search(searchStr) ?? -1) > -1)
                    return true;

                return false;
            });
        }

        return <>
            <Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate Authority</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        <Nav.Item disabled={!this.state.CANodeInfo.createRights || (this.state.trainingCenter === undefined || this.state.trainingCenter === null || this.state.trainingCenter.length === 0)} icon={<Icon icon="user" />} onClick={this.modalUploadHolders}>Register Holders</Nav.Item>
                        {this.props.role !== Roles.CAOWner.toString() ? null : <Nav.Item disabled={!this.state.CANodeInfo.createRights || (this.state.trainingCenter === undefined || this.state.trainingCenter === null || this.state.trainingCenter.length === 0)} icon={<Icon icon="user-plus" />} onClick={this.modalAddCAAdmim}>Add CA User</Nav.Item>}
                        {this.props.role === Roles.CAOWner.toString() && (this.state.trainingCenter === undefined || this.state.trainingCenter === null || this.state.trainingCenter.length === 0) ? <Nav.Item disabled={!this.state.CANodeInfo.createRights} icon={<Icon icon="connectdevelop" />} onClick={this.modalSetTrainingCenter}>Set Training Center</Nav.Item> : null}
                        <Nav.Item disabled={!this.state.CANodeInfo.createRights || this.state.organization === undefined || this.state.organization === null || this.state.organization.length === 0} icon={<Icon icon="check-circle" />} onClick={() => {
                            //let date = new Date();
                            //let dateOneMonth = new Date(date.getFullYear(), date.getMonth() + 1, date.getDay());
                            this.setState({
                                showModal: CAModalModes.AddCertificate,
                                addCertificateErrorMessage: undefined,
                                modalData: {
                                    certificate_authority: this.state.caAddress,
                                    governing_body: '',
                                    revoked: false,
                                }
                            })
                        }} >Add Certificate</Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>

            <Form formValue={this.state.CANodeInfo} layout="horizontal" style={{ margin: 10 }}>
                <FormGroup>
                    <ControlLabel>Certificate Authority</ControlLabel>
                    <FormControl name="ca" plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Certificate Authority Name</ControlLabel>
                    <FormControl name="name" plaintext={true} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Training Center</ControlLabel>
                    <FormControl value={this.state.trainingCenter} plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>CreateRight</ControlLabel>
                    <FormControl name="createRights" plaintext={true}
                        accepter={Checkbox as any}
                        checked={this.state.CANodeInfo.createRights}
                    />
                </FormGroup>
                {this.props.role === Roles.CAOWner && this.state.caUsers !== undefined && this.state.caUsers.length > 0 ? <Panel header="My Certificate Authority" collapsible>
                    <List bordered>
                        {this.state.caUsers.map((item, index) =>
                            <List.Item key={item.email} index={index}>
                                {item.name} - {item.email}
                            </List.Item>
                        )}
                    </List>
                </Panel> : null}

            </Form>


            <Panel key="certificate-table" shaded bodyFill style={{ margin: 20 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Certificates</div>
                    </Navbar.Header>
                    <Navbar.Body>
                        <IconButton onClick={(c) => { this.setState({ criteria: undefined }); this.getData(1); }} style={{ marginTop: 12 }} icon={<Icon icon="refresh" />}>
                        </IconButton>
                        <Nav pullRight >
                            <IconButton style={{ margin: 20 }} icon={<Icon icon="search-peoples" />} onClick={this.complexSearch} />
                        </Nav>
                    </Navbar.Body>
                </Navbar>

                <Table height={500} rowHeight={46} data={data}

                    loading={this.state.loading}>
                    <Table.Column width={50} align="center" >
                        <Table.HeaderCell></Table.HeaderCell>
                        <RadioCell
                            dataKey="address"
                            selectedKey={this.state.selectedCertificateKey}
                            onChange={this.handleCertificateRowSelect}
                        />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.Cell dataKey="title" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Holder</Table.HeaderCell>
                        <Table.Cell dataKey="holderName" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Revoked</Table.HeaderCell>
                        <DisabledCell
                            dataKey="revoked"
                        />
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Valid</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CertificateInfo) => {
                                return (<div>
                                    {!rowData.valid ? <Icon
                                        style={{ color: 'red' }}
                                        icon='minus-circle'
                                    /> : <Icon
                                        style={{ color: 'green' }}
                                        icon='check-circle'
                                    />}
                                </div>)

                            }}
                        </Table.Cell>
                    </Table.Column>

                </Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    maxButtons={6}
                    size="sm"
                    boundaryLinks={true}
                    pages={Math.ceil(this.state.totalCertificates / 10)}
                    activePage={this.state.activePage}
                    onSelect={this.handleSelect}
                />
            </Panel>
        </>
    }
    // --------------------------------------------------------------------------------------------------

    modalAddCAAdmim = async () => {
        this.setState({ showModal: CAModalModes.AddCAAdmin })
    }

    modalSetTrainingCenter = async () => {
        this.setState({ showModal: CAModalModes.SetTrainingCenter })
    }
    // --------------------------------------------------------------------------------------------------

    modalUploadHolders = async () => {
        this.setState({ showModal: CAModalModes.UploadHolders });
    }
    // --------------------------------------------------------------------------------------------------
    modalRevokeCertificate = async () => {
        if (this.state.selectedCertificateKey == null)
            return;
        this.setState({ showModal: CAModalModes.Processing });
        let response = await this.NodeService.revokeCertificate(this.state.selectedCertificateKey);
        if (response?.code !== 0)
            this.setState({ showModal: CAModalModes.Error, modalData: { message: response.message } })
        else {
            this.closeModal();
            let nodeInfo: CANodeInfo = { ...this.state.CANodeInfo! };
            var certs = nodeInfo!.certs;
            var certInfo = _.find(certs, x => x.address === this.state.selectedCertificateKey);
            if (certInfo !== null && certInfo !== undefined) {
                certInfo.revoked = true;
                let caInfo: CANodeInfo = {
                    ca: nodeInfo.ca,
                    name: nodeInfo.name,
                    certs: nodeInfo.certs,
                    certsLength: nodeInfo.certsLength,
                    createRights: nodeInfo.createRights,
                    balance: -1
                };
                this.setState({ CANodeInfo: caInfo });
            }
        }
    }

    // --------------------------------------------------------------------------------------------------

    closeModal = () => this.setState({ showModal: undefined })
    handleModalDataChange = (value: any) => this.setState({ modalData: value });

    // --------------------------------------------------------------------------------------------------

    handleSearchCertificatesResult = async (v: SearchCertificateCriteria, page: number, setCriteria: boolean) => {
        let caInfo: CANodeInfo = {
            ca: this.state.caAddress!,
            name: this.state.organization!,
            certs: [],
            certsLength: 0,
            createRights: this.state.CANodeInfo?.createRights!,
            balance: -1
        };
        var caAddress = this.state.caAddress!;
        if (setCriteria)
            this.setState({ loading: true, showModal: undefined, criteria: v });
        else
            this.setState({ loading: true, showModal: undefined });
        try {
            var info = await this.NodeService.caSearchCaCertificates(caAddress, v, page, 10);
            this.setState({ totalCertificates: info.totalCount, loading: false });
            caInfo.certs = info.result;
            caInfo.certsLength = caInfo.certs.length;

        }
        catch (err) {
            console.error(err);
            this.setState({ loading: false })
        }
        this.setState({ CANodeInfo: caInfo });
    }
    // --------------------------------------------------------------------------------------------------

    renderModals(modal: CAModalModes | undefined, modalData: any, closeModal: () => void) {

        switch (modal) {
            // --------------------------------
            case CAModalModes.SearchCertificates:
                return <>
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        <SearchCertificate onSearchComplete={(v) => this.handleSearchCertificatesResult(v, 1, true)} ca={this.state.caAddress!}></SearchCertificate>
                    </Modal.Body>
                </>
            // --------------------------------
            case CAModalModes.Processing:
                return <>
                    <Modal.Header>
                        <Modal.Title>Processing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center' }}>
                            <Loader size="md" />
                        </div>
                    </Modal.Body>
                </>
            // --------------------------------
            case CAModalModes.Error: {
                let data: ModalError = modalData as ModalError;
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="warning"
                                size="3x"
                                style={{
                                    color: '#f04f43',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                {data.message}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeModal} appearance="subtle">Close</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.Success: {
                let data: ModalError = modalData as ModalError;
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="ok-circle"
                                size="3x"
                                style={{
                                    color: '#4CAF50',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                {data.message}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeModal} appearance="subtle">Close</Button>
                    </Modal.Footer>
                </>
            }

            // --------------------------------
            case CAModalModes.AddCertificate: {
                return <>
                    <Modal.Header>
                        <Modal.Title>New Certificate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddCertificateForm ca={this.state.caAddress!} centerName={this.state.trainingCenter!} onSubmitComplete={(res, mes) => this.handleAddCertFormCompletion(res, mes)}></AddCertificateForm>
                    </Modal.Body>
                </>
            }

            // --------------------------------

            case CAModalModes.SetTrainingCenter: {
                return <>
                    <Modal.Header>
                        <Modal.Title>Set Training Center</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Body>
                            <Form layout="horizontal">
                                <FormGroup>
                                    <ControlLabel>Training Center</ControlLabel>
                                    <SelectPicker onSelect={this.selectTrainingCenter} data={this.state.trainingCenters} ></SelectPicker>
                                </FormGroup>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={!this.state.setCATrainingCenterEnabled} onClick={this.setTrainingCenter} appearance="primary">Apply</Button>
                            {this.state.setCATrainingCenterEnabled ? null : <Loader></Loader>}
                        </Modal.Footer>
                    </Modal.Body>
                </>
            }

            // --------------------------------
            case CAModalModes.CertRevoke: {
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="remind"
                                size="3x"
                                style={{
                                    color: '#ffb300',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                Revoke the certificate to {this.state.selectedCertificateKey}.
                                <br />
                                Are you sure you want to proceed ?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalRevokeCertificate} appearance="primary">Yes</Button>
                        <Button onClick={closeModal} appearance="subtle">No</Button>
                    </Modal.Footer>
                </>
            }

            // --------------------------------
            case CAModalModes.Holder: {
                let holderAddress = this.state.selectedCertificateInfo?.holder;
                var extra: any = {};
                try {
                    extra = JSON.parse(this.state.selectedCertificateInfo?.extra as string);
                }
                catch (err) {

                }
                var info: HolderInfo =
                {
                    address: holderAddress!,
                    firstName: extra["firstName"],
                    middleName: extra["middleName"],
                    lastName: extra["lastName"],
                    email: extra["email"],
                    date_of_birth: extra["dob"],
                    verified: false,
                    date_of_verification: new Date()

                };
                return <>
                    <Modal.Body>
                        <HolderCertificates
                            height={Math.ceil(this.props.height * 0.8)}
                            holderAddress={holderAddress}
                            holderInfo={info}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeModal} appearance="primary">Close</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------

            case CAModalModes.UploadHolders: {
                const conf = getConfigurations();

                return <>
                    <Navbar>
                        <Navbar.Header>
                            <div style={{
                                lineHeight: "56px",
                                paddingLeft: 20,
                                fontSize: "120%",
                                fontWeight: "bolder"
                            }}>Upload csv</div>
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav pullRight >

                                <Nav.Item pullRight icon={<Icon style={{ fontSize: 18 }} icon="close-circle" />} onClick={closeModal} />
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                    <div>
                        <Uploader
                            style={{ padding: 10 }}
                            disabled={this.state.uploadInProgress}
                            disabledFileItem
                            headers={NodeService.GetTokenHeader()}
                            action={`${conf.serverRoot}uploadFile`}
                            onChange={this.handleUpload}
                            onSuccess={this.onUploadCompleted}
                            onError={this.onUploadFailed}
                        />

                        {this.state.uploadInProgress ? <Loader></Loader> : null}
                        {this.state.uploadSuccess &&
                            <div>
                                {/* <Icon style={{ color: "#fecb00" }} icon='check-circle' size='2x'></Icon>
                                <Button style={{ float: "right" }} onClick={() => this.setState({ uploadSuccess: false, showModal: undefined })} appearance="primary" > OK</Button> */}
                                <div>You can monitor the registration progress of the Seafarer(s) through the Tasks Dashboard</div>
                                <div><Icon style={{ color: "#fecb00", marginTop: 10 }} icon='check-circle' size='2x'></Icon>
                                    <Button style={{ marginTop: 10, float: "right" }} onClick={() => this.setState({ uploadSuccess: false, showModal: undefined })} appearance="primary" > OK</Button></div>

                            </div>}
                        {this.state.uploadSuccess !== undefined && this.state.uploadSuccess === false && <div> <Icon style={{ color: "red" }} icon='close-circle' > {this.state.error}</Icon>


                            <Button style={{ float: "right" }} onClick={() => this.setState({ uploadSuccess: false, showModal: undefined })} appearance="primary" > OK</Button>
                        </div>}

                    </div>
                </>
            }
            case CAModalModes.AddCAAdmin: {
                return <>
                    <Modal.Header>
                        <Modal.Title>New CA User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onChange={(formValue) => {
                            this.setState({ caadminUser: formValue as CAAdminUser });
                        }}>
                            <FormGroup>
                                <ControlLabel>Name</ControlLabel>
                                <FormControl name="name" />
                                <HelpBlock tooltip>Required</HelpBlock>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Email</ControlLabel>
                                <FormControl name="email" type="email" />
                                <HelpBlock tooltip>Required</HelpBlock>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.addCAUser} appearance="primary">Add</Button>

                    </Modal.Footer>
                </>
            }
        }

        return null
    }
    // --------------------------------------------------------------------------------------------------

    async handleAddCertFormCompletion(res: boolean, msg: string): Promise<void> {
        if (res) {
            this.closeModal();
            toastr.info("The issuance of the Certificate(s) can be monitored through the Tasks Dashboard");
        }
        else {
            this.setState({ showModal: CAModalModes.Error, modalData: { message: msg } })
        }
    }
    // --------------------------------------------------------------------------------------------------

    addCAUser = async () => {
        if (this.state.caadminUser?.email === undefined || this.state.caadminUser?.email === null || this.state.caadminUser?.name === undefined || this.state.caadminUser?.name === undefined)
            return;
        try {
            this.setState({ showModal: CAModalModes.Processing })
            var result = await this.NodeService.addcaAddmim(this.state.caadminUser.email, this.state.caadminUser.name);
            if (result.code === 1) {
                var modalError: ModalError = {
                    message: result.message
                };
                this.setState({ showModal: CAModalModes.Error, addCaAdminEnabled: true, modalData: modalError })

            }
            else {
                var resp = (await this.NodeService.getOrganizationStructure());
                this.setState({ showModal: undefined, addCaAdminEnabled: true, caUsers: resp });

            }

        }
        catch (ex) {
            console.error(ex);
            const err = ex as AxiosError;
            var Error: ModalError = {
                message: err?.response?.data?.message
            };
            this.setState({ showModal: CAModalModes.Error, addCaAdminEnabled: true, modalData: Error })
        }

    }
    // --------------------------------------------------------------------------------------------------

    setTrainingCenter = async () => {
        if (this.state.trainingCenter === undefined || this.state.trainingCenter === null)
            return;
        try {
            this.setState({ setCATrainingCenterEnabled: false })
            var result = await this.NodeService.setTrainingCenter(this.state.caAddress, this.state.trainingCenter);
            if (result.code === 1) {
                var modalError: ModalError = {
                    message: result.message
                };
                this.setState({ showModal: CAModalModes.Error, setCATrainingCenterEnabled: true, modalData: modalError })

            }
            else {
                let cainfo = await this.NodeService.getAddress(true);
                this.setState({ showModal: undefined, trainingCenter: cainfo.trainingCenter })
            }

        }
        catch (ex) {
            console.error(ex);
            const err = ex as AxiosError;
            var Error: ModalError = {
                message: err?.response?.data?.message
            };
            this.setState({ showModal: CAModalModes.Error, setCATrainingCenterEnabled: true, modalData: Error })
        }

    }
    // --------------------------------------------------------------------------------------------------
    getModalClassName(mode?: CAModalModes) {
        if (mode === CAModalModes.Holder ||
            mode === CAModalModes.SelectHolder
        )
            return "dialogHolder";
        return undefined;
    }
    // --------------------------------------------------------------------------------------------------

    onUploadCompleted = (response: any) => {

        this.setState({ uploadInProgress: false, uploadSuccess: true },);

    }
    // --------------------------------------------------------------------------------------------------

    onUploadFailed = (response: any) => {

        this.setState({ uploadInProgress: false, uploadSuccess: false, error: response.response });

    }

    // --------------------------------------------------------------------------------------------------
    render() {
        return <div style={{ display: "flex" }} className="ca">
            <Panel shaded bodyFill style={{ flex: 1, height: this.props.height - 20, margin: 10, overflow: "auto" }}>
                {this.renderCAPanel()}
            </Panel>

            <Panel className="ca-certificate" shaded bodyFill style={{ flex: 1, margin: 10 }}>
                {this.renderCertificatePanel()}
            </Panel>

            <Modal
                key="modal"
                backdrop={this.state.modalBackdrop}
                className={this.getModalClassName(this.state.showModal)}
                show={this.state.showModal != null}
                onHide={this.closeModal}
                overflow={false}>
                {this.renderModals(this.state.showModal, this.state.modalData, this.closeModal)}
            </Modal>


        </div>
    }
}
// =======================================================================================================