import * as React from 'react';
import { Button, ButtonToolbar, ControlLabel, DateRangePicker, ErrorMessage, Form, FormControl, FormGroup, HelpBlock, Loader, Panel, Schema, SelectPicker } from 'rsuite';
import _ from 'lodash';
import { format } from 'date-fns';
import { NodeService } from '../services/NodeService';
import { CertificatesInfo, SearchCertificateCriteria, Training, TrainingMV } from '../services/ModelView';
import { CrewmanService } from '../services/CrewmanService';
interface SearchCertificateProps {
    ca: string
    onSearchComplete: (criteria:SearchCertificateCriteria) => void;
}



interface SearchCertificateState {
    formValue: SearchCertificateCriteria,
    trainings: TrainingMV[] | undefined
    isProcessing: boolean,
    dateError: boolean,
}
const { StringType, DateType } = Schema.Types;
const model = Schema.Model(
    {
        /*name: StringType().isRequiredOrEmpty().addRule((value, data) => {
            if (value !== undefined && value !== null && value.length > 0)
                return true;
            else {
                if (data.email !== undefined && data.email !== null && data.email.length > 0)
                    return true;
                if (data.crewId !== undefined && data.crewId !== null && data.crewId.length > 0)
                    return true;
            }
            return false;

        }, 'Name or Email or CrewId should be filled'),


        email: StringType().isRequiredOrEmpty().addRule((value, data) => {
            if (value !== undefined && value !== null && value.length > 0)
                return true;
            else {
                if (data.name !== undefined && data.name !== null && data.name.length > 0)
                    return true;
                if (data.crewId !== undefined && data.crewId !== null && data.crewId.length > 0)
                    return true;
            }
            return false;

        }, 'Name or Email or CrewId should be filled'),

        crewId: StringType().isRequiredOrEmpty().addRule((value, data) => {
            if (value !== undefined && value !== null && value.length > 0)
                return true;
            else {
                if (data.name !== undefined && data.name !== null && data.name.length > 0)
                    return true;
                if (data.email !== undefined && data.email !== null && data.email.length > 0)
                    return true;
            }
            return false;

        }, 'Name or Email or CrewId should be filled'),
        */

    }
)
// =======================================================================================================
export class SearchCertificate extends React.Component<SearchCertificateProps, SearchCertificateState> {
    form: any;
    private NodeService: NodeService;
    constructor(props: SearchCertificateProps) {
        super(props);
        this.state =
        {
            formValue:
            {
                name: '',
                email: '',
                fromDate: undefined,
                toDate: undefined,
                sessionId: "",
                crewId: '',
                certType: ''
            },
            isProcessing: false,
            dateError: false,
            trainings: undefined
        }
        this.NodeService = new NodeService();
    }

    // --------------------------------------------------------------------------------------------------
    componentDidMount = async () => {
        var trainings = await CrewmanService.getTrainings();
        this.setState({ trainings: trainings });
    }

    // --------------------------------------------------------------------------------------------------
    handleDates = (value: Array<Date>) => {

        const myDate1 = format(value[0], "YYYY-MM-DD").split('T')[0];
        const myDate2 = format(value[1], "YYYY-MM-DD").split('T')[0];
        var formdata: SearchCertificateCriteria = { ...this.state.formValue };
        _.set(formdata, "fromDate", myDate1);
        _.set(formdata, "toDate", myDate2);
        this.setState({ formValue: formdata, dateError: false });
    }
    //------------------------------------------------------------------------------------------------------
    complexCertificatesSearch = async () => {

        var hasErrors = false;
        if (!this.form.check()) {
            hasErrors = true;
        }

        if (hasErrors)
            return;

        this.props.onSearchComplete(this.state.formValue);

     
    }

    // ------------------------------------------------------------------------------------------------------

    selectTraining = (event: Training) => {
        if (event !== null && event !== undefined) {
            var type = event.trainingId
            var formdata: SearchCertificateCriteria = { ...this.state.formValue };
            _.set(formdata, "certType", type);
            this.setState({ formValue: formdata })
        }
    }
    // --------------------------------------------------------------------------------------------------
    render() {
        const { formValue } = this.state;
        return (
            <Panel >
                <Form layout="horizontal" ref={(ref: any) => (this.form = ref)}
                    onChange={(formValue) => {
                        this.setState({ formValue: formValue as SearchCertificateCriteria });
                    }}
                    formValue={formValue}
                    model={model}>
                    <FormGroup>
                        <ControlLabel>Seafarer Name</ControlLabel>
                        <FormControl name="name" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Seafarer Email</ControlLabel>
                        <FormControl name="email" type="email" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Seafarer Crew Id</ControlLabel>
                        <FormControl name="crewId" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Session Id</ControlLabel>
                        <FormControl name="sessionId" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Certificate Type</ControlLabel>
                        <SelectPicker onSelect={this.selectTraining} data={this.state.trainings!}></SelectPicker>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Date of issuance (range) </ControlLabel>
                        <div className={'rs-form-control-wrapper'}>
                            <DateRangePicker e style={{ minWidth: "300px", float: "left", margin: 0 }} utcOffset={0} preventOverflow={true} onOk={(v) => this.handleDates(v as Array<Date>)}></DateRangePicker>
                            <ErrorMessage show={this.state.dateError} style={{ color: "red" }} placement="bottomStart">This field is Required </ErrorMessage>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={this.complexCertificatesSearch}>Search</Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
                {this.state.isProcessing ?
                    <div style={{ textAlign: "center" }}>
                        <Loader /></div> : null
                }
            </Panel>
        );
    }
}
// =======================================================================================================
