import * as React from 'react';
import { Icon, Nav, Navbar, Timeline } from 'rsuite';
import { NodeService } from '../services/NodeService';
import { BackgroundJob } from './BackGroundJobsDashboard';

export interface BackgroundJobDetailsProps {
    jobId: string | undefined
    onCompleted: (status: string) => void;
}

export interface BackgroundJobDetailsState {
    selectedJob: BackgroundJob | undefined;
}
// =======================================================================================================
export class BackgroundJobLiveView extends React.Component<BackgroundJobDetailsProps, BackgroundJobDetailsState> {
    private NodeService: NodeService;
    interval: NodeJS.Timeout | undefined;

    constructor(props: BackgroundJobDetailsProps) {
        super(props);
        this.state =
        {
            selectedJob: undefined,
        }
        this.NodeService = new NodeService();
    }
    // --------------------------------------------------------------------------------------------------

    componentDidMount = async () => {
        console.info("Live mount");

        if (this.props.jobId !== undefined) {
            this.getJob(this.props.jobId!);
            this.interval = setInterval(() => {
                this.getJob(this.props.jobId!);
            }, 5000);
        }
    }


    componentWillUnmount() {
        if (this.interval !== undefined)
            clearInterval(this.interval!);
    }
    async getJob(jobId: string) {
        var job = await this.NodeService.getJob(jobId);
        if (job !== undefined) {
            this.setState({ selectedJob: job })
            if (job.progress === 100 && this.interval !== undefined) {
                clearInterval(this.interval!);
                this.props.onCompleted(job.status);
            }
        }
    }
    async componentWillReceiveProps(nextProps: BackgroundJobDetailsProps) {
        if (nextProps.jobId !== undefined && this.props.jobId !== nextProps.jobId) {
            console.info("Live next Props")
            if (this.interval !== undefined)
                clearInterval(this.interval!);
            this.getJob(nextProps.jobId!);
            this.interval = setInterval(() => {
                this.getJob(this.props.jobId!);
            }, 5000);
        }
    }
    // --------------------------------------------------------------------------------------------------
    createDots(logs: Array<string>) {
        let items: Array<any> = [];
        // create buttons for each role
        if (logs === null || logs === undefined || logs.length === 0)
            return;
        logs.forEach((value: string) => {
            var error = value.startsWith("Fail");
            if (error) {
                items.push(
                    <Timeline.Item dot={<Icon icon="close" style={{ background: "red", color: '#fff' }} />}>
                        <p style={{ paddingTop: 10 }}>{value}</p>
                    </Timeline.Item>
                );
            }
            else {
                items.push(
                    <Timeline.Item dot={<Icon icon="check" style={{ background: '#15b215', color: '#fff' }} />}>
                        <p style={{ paddingTop: 10 }}>{value}</p>
                    </Timeline.Item>
                );
            }
        }
        );
        return items;
    }
    // --------------------------------------------------------------------------------------------------
    renderJobDetails() {
        if (this.state.selectedJob === null || this.state.selectedJob === undefined)
            return <>
                <Navbar appearance={"default"}>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }}>#Task Id {this.props.jobId}</div>
                    </Navbar.Header>
                </Navbar>
            </>

        return <>
            <Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }}>#Task Id {this.state.selectedJob.jobId}</div>
                </Navbar.Header>
                <Navbar.Body>

                    <Nav pullRight>
                        <Nav.Item icon={<Icon icon="pie-chart" />}>{this.state.selectedJob.progress}%</Nav.Item>
                        <Nav.Item>{this.state.selectedJob.message}</Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
            <Timeline className="custom-timeline">
                {this.createDots(this.state.selectedJob.logs)}

            </Timeline>
        </>
    }

    // --------------------------------------------------------------------------------------------------
    render() {
        return (
            <div>
                { this.renderJobDetails()}
            </div>
        );
    }
}
// =======================================================================================================