import React from 'react';
import _ from 'lodash';

import {  Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { CertificateExtra, CertificateInfo,  } from '../services/ModelView';
import {  CertificatePdfProps, generateQR, SingleCertificatePage } from '../lib/Certificates';
import { CrewmanService } from '../services/CrewmanService';
import { Loader } from 'rsuite';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});
// =======================================================================================================
export interface BatchDocumentsProps {
    certificates: CertificateInfo[] | null | undefined
}
// ------------------------------------------------------------------------------------------------------
export interface BatchDocumentsState {
    certPdfs: any[] | undefined;
    loading: boolean;
}
// ------------------------------------------------------------------------------------------------------
export default class BatchDocuments extends React.Component<BatchDocumentsProps, BatchDocumentsState> {
    constructor(props: BatchDocumentsProps) {
        super(props);
        this.state =
        {
            certPdfs: undefined,
            loading: true
        }
    }
    // --------------------------------------------------------------------------------------------------
    async componentDidMount() {
        if (this.props.certificates !== null && this.props.certificates !== undefined && this.props.certificates.length > 0) {
            var pages: any[] = [];
            var _certificates = this.props.certificates;
            for (const certificate of _certificates) {

                var qr = await generateQR(certificate.address);
                var lines = await CrewmanService.getCertificateInfo(certificate?.type);
                let extra = JSON.parse(certificate.extra as string) as CertificateExtra;

                var pdfProps: CertificatePdfProps =
                {
                    certInfo: certificate,
                    img: qr,
                    curriculumSummary: lines
                }
                var singlePage = SingleCertificatePage(pdfProps, extra, qr, lines);
                if (singlePage !== null && singlePage !== undefined)
                    pages.push(singlePage);

            };

            this.setState({ certPdfs: pages, loading: false });
        }
    }

    // --------------------------------------------------------------------------------------------------
    render() {
        return <div>{
            this.state.loading ? <Loader></Loader> : <PDFViewer style={{ width: "100%", height: 1000, borderStyle: "dotted", borderRadius: 20 }}>
                <Document>
                    {this.state.certPdfs}
                </Document>
            </PDFViewer>
        }
        </div>
    }
}
// =======================================================================================================