import { Log, User, UserManager } from 'oidc-client';
import { getConfigurations } from '../Configurations';

export class AuthService {
  // --------------------------------------------------------------------------------------------------

  public static userManager: UserManager | null = null;
  public currentUser: User | null;

  // --------------------------------------------------------------------------------------------------

  constructor() {
    const Configurations = getConfigurations();
    const settings = {
      authority: Configurations.stsAuthority,
      client_id: Configurations.clientId,
      redirect_uri: `${Configurations.clientRoot}/signin-callback.html`,
      silent_redirect_uri: `${Configurations.clientRoot}/silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${Configurations.clientRoot}`,
      //implicit
      response_type: 'token id_token',
      scope: Configurations.clientScope
    };
    if (AuthService.userManager === null || AuthService.userManager === undefined) {
      AuthService.userManager = new UserManager(settings);
      AuthService.userManager.events.addAccessTokenExpired(this.tokenExpired);
      AuthService.userManager.events.addAccessTokenExpiring(this.refreshToken);
    }

    this.currentUser = null;

    Log.logger = console;
    Log.level = Log.INFO;
  }

  // --------------------------------------------------------------------------------------------------

  public getUser(): Promise<User | null> {
    return AuthService.userManager!.getUser();
  }
  // --------------------------------------------------------------------------------------------------

  public login(): Promise<void> {
    return AuthService.userManager!.signinRedirect();
  }
  // --------------------------------------------------------------------------------------------------

  public renewToken(): Promise<User> {
    return AuthService.userManager!.signinSilent();
  }
  // --------------------------------------------------------------------------------------------------

  public logout(): Promise<void> {
    return AuthService.userManager!.signoutRedirect();
  }
  // --------------------------------------------------------------------------------------------------
  public tokenExpired = async () => {
    console.log(" critical auth service, token expired")
    var user = await AuthService.userManager!.getUser();
    if (user?.expired)
      await this.logout();
  }
  // --------------------------------------------------------------------------------------------------

  public refreshToken = async () => {
    this.currentUser = await AuthService.userManager!.signinSilent();
    console.log("auth service, token refreshed")
  }
  // --------------------------------------------------------------------------------------------------
  public getAccessToken = () => {
    return this.currentUser?.access_token;
  }
  // --------------------------------------------------------------------------------------------------


}
