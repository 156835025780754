function value_or(value: string | undefined, default_value: string) : string {
  return value === undefined ? default_value : value;
}

// =======================================================================================================

export interface IConfigurations {
  stsAuthority: string;
  clientId: string;
  clientRoot: string;
  clientScope: string;
  serverRoot: string;
}

// =======================================================================================================
export function getConfigurations(): IConfigurations {
  //  -------------------------------------
  if (process.env.APP_CONF === "release") {
    if (process.env.APP_NAME === "gb")
      return {
        stsAuthority: 'https://mscauth.electiconsulting.com/',
        clientId: 'gb',
        clientRoot: 'https://msc-gb.electiconsulting.com',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'https://mscsolution.electiconsulting.com/msc/api/',

      };

    if (process.env.APP_NAME === "ca")
      return {
        stsAuthority: 'https://mscauth.electiconsulting.com/',
        clientId: 'ca',
        clientRoot: 'https://msc-ca.electiconsulting.com',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'https://mscsolution.electiconsulting.com/msc/api/',

      };

    if (process.env.APP_NAME === "nfm")
      return {
        stsAuthority: 'https://mscauth.electiconsulting.com/',
        clientId: 'gb-1',
        clientRoot: 'https://msc-nfm.electiconsulting.com',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'https://mscsolution.electiconsulting.com/msc/api/',
      };

    if (process.env.APP_NAME === "crf")
      return {
        stsAuthority: '',
        clientId: '',
        clientRoot: '',
        clientScope: '',
        serverRoot: 'https://mscsolution.electiconsulting.com/msc/api/',
      };
  }

  //  -------------------------------------
  if (process.env.APP_CONF === "local") {
    if (process.env.APP_NAME === "gb")
      return {
        stsAuthority: 'http://localhost:3691/',
        clientId: 'msclocal',
        clientRoot: 'http://localhost:3000',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'http://localhost:5000/msc/api/',
      };


    if (process.env.APP_NAME === "ca")
      return {
        stsAuthority: 'http://localhost:3691/',
        clientId: 'msclocal',
        clientRoot: 'http://localhost:3000',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'http://localhost:5000/msc/api/'

      };

    if (process.env.APP_NAME === "nfm")
      return {
        stsAuthority: 'http://localhost:3691/',
        clientId: 'msclocal',
        clientRoot: 'http://localhost:3000',
        clientScope: 'openid profile role jp_api.user',
        serverRoot: 'http://localhost:5000/msc/api/',
      };

    if (process.env.APP_NAME === "crf")
      return {
        stsAuthority: '',
        clientId: '',
        clientRoot: '',
        clientScope: '',
        serverRoot: 'http://localhost:5000/msc/api/',
      };
  }

  //  -------------------------------------
  if (process.env.APP_CONF === "env") {
    if (process.env.APP_NAME === "gb") {
      return {
        stsAuthority: value_or(process.env.NACRE_IDENTITY_URL, "http://localhost:3691/"),
        serverRoot: `${value_or(process.env.NACRE_SERVER_ROOT, 'http://localhost:5000/')}msc/api/`,
        clientId: value_or(process.env.NACRE_GB_CLIENT_ID, "msclocal"),
        clientRoot: value_or(process.env.NACRE_GB_CLIENT_ROOT, 'http://localhost:3000'),
        clientScope: value_or(process.env.NACRE_CLIENT_SCOPE, 'openid profile role jp_api.user'),
      };
    }

    if (process.env.APP_NAME === "ca") {
      return {
        stsAuthority: value_or(process.env.NACRE_IDENTITY_URL, "http://localhost:3691/"),
        serverRoot: `${value_or(process.env.NACRE_SERVER_ROOT, 'http://localhost:5000/')}msc/api/`,
        clientId: value_or(process.env.NACRE_CA_CLIENT_ID, 'msclocal'),
        clientRoot: value_or(process.env.NACRE_CA_CLIENT_ROOT, 'http://localhost:3000'),
        clientScope: value_or(process.env.NACRE_CLIENT_SCOPE, 'openid profile role jp_api.user'),
      };
    }

    if (process.env.APP_NAME === "nfm") {
      return {
        stsAuthority: value_or(process.env.NACRE_IDENTITY_URL, "http://localhost:3691/"),
        serverRoot: `${value_or(process.env.NACRE_SERVER_ROOT, 'http://localhost:5000/')}msc/api/`,
        clientId: value_or(process.env.NACRE_NFM_CLIENT_ID, 'msclocal'),
        clientRoot: value_or(process.env.NACRE_NFM_CLIENT_ROOT, 'http://localhost:3000'),
        clientScope: value_or(process.env.NACRE_CLIENT_SCOPE, 'openid profile role jp_api.user'),
      };
    }

    if (process.env.APP_NAME === "crf") {
      return {
        stsAuthority: '',
        serverRoot: `${value_or(process.env.NACRE_SERVER_ROOT, 'http://localhost:5000/')}msc/api/`,
        clientId: '',
        clientRoot: '',
        clientScope: ''
      };
    }
  }

  //  -------------------------------------
  return {
    stsAuthority: '',
    clientId: '',
    clientRoot: '',
    clientScope: '',
    serverRoot: '',
  };
}
// =======================================================================================================
