import { CurriculumSummary, TrainingMV } from "./ModelView";
import { NodeService } from "./NodeService";

export class CrewmanService {

    static nodeService: NodeService = new NodeService();
    static curriculumInfo: Map<string, CurriculumSummary> = new Map<string, CurriculumSummary>();
    static trainings: Array<TrainingMV> = new Array<TrainingMV>();

    static async getCertificateInfo(type: string | undefined, isGuest: boolean = false) {
        if (type !== undefined)

            if (this.curriculumInfo.has(type))
                return this.curriculumInfo.get(type);
            else {
                var resp = await this.nodeService.getCurriculumInfo(type, isGuest);
                this.curriculumInfo.set(type, resp);
                return resp;
            }
    }

    static async getTrainings() {

        if (this.trainings.length > 0)
            return this.trainings;
        else {
            var resp = await this.nodeService.getTrainings();
            this.trainings = resp;
            return resp;
        }
    }
}