import React, { SyntheticEvent } from 'react';
import _ from 'lodash';

import {
    Table, Radio, Placeholder, Panel, Navbar,
    Nav, Input, Form, FormGroup, ControlLabel,
    FormControl, Icon, Modal, Button, Loader, Pagination
} from 'rsuite';
import revokeIcon from '../img/revoke_icon.png';

import './HolderCertificates.less';
import { NodeService } from '../services/NodeService';
import { CertificateInfo, CurriculumSummary, HolderInfo } from '../services/ModelView';
import { Certificate, generateQR } from './Certificates';
import { CrewmanService } from '../services/CrewmanService';

// =======================================================================================================
interface RadioCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    selectedKey: string | null;
    dataKey: string
}
const RadioCell = (props: RadioCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            <Radio
                value={props.rowData[props.dataKey]}
                inline
                onChange={props.onChange}
                checked={props.selectedKey === props.rowData[props.dataKey]}
            />
        </div>
    </Table.Cell>
}

interface DisabledCellProps {
    rowData?: any;
    onChange?: (value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement>) => void;
    dataKey: string
}
const DisabledCell = (props: DisabledCellProps) => {
    let extraProps = _.omit(props, ["rowData", "dataKey", "selectedKey", "onChange"]);
    return <Table.Cell {...extraProps} style={{ padding: 0 }}>
        <div style={{ lineHeight: '46px' }}>
            {
                (props.rowData[props.dataKey]) ?
                    <Icon
                        style={{ color: 'red' }}
                        icon='minus-circle'
                    /> :
                    null
            }
        </div>
    </Table.Cell>
}
// ------------------------------------------------------------------------------------------------------
export enum CAModalModes {
    Processing,
    Error,
    AddCertificate,
    CertRevoke
}
export interface ModalError {
    message: string
}
export type ModalData = Partial<CertificateInfo> | ModalError | undefined
// ------------------------------------------------------------------------------------------------------
export interface HolderCertificatesProps {
    height: number;
    holderAddress?: string;
    holderInfo?: HolderInfo;
}
// ------------------------------------------------------------------------------------------------------
export interface HolderCertificatesState {
    selectedNodeKey: string | null;

    selectedCertificateKey: string | null;
    selectedCertificateInfo: Partial<CertificateInfo> | undefined;
    selectedCertificateImage: string | undefined;
    selectedCertificateCurriculum: CurriculumSummary | undefined;

    //selectedNodeInfo: CANodeInfo | undefined;
    holderCertificates: CertificateInfo[] | undefined;
    searchCertificateKey: string | null;
    sortNodeCertsColumn: string | undefined;
    sortNodeCertsType: "desc" | "asc" | undefined;

    showModal?: CAModalModes;
    modalBackdrop: 'static' | true | false;
    modalData?: ModalData;

    loading: boolean;
    totalCertificates: number;
    activePage: number;
}
// ------------------------------------------------------------------------------------------------------
export default class HolderCertificates extends React.Component<HolderCertificatesProps, HolderCertificatesState> {
    private NodeService: NodeService;
    constructor(props: HolderCertificatesProps) {
        super(props);
        this.state = {
            selectedNodeKey: '',
            selectedCertificateKey: null,
            selectedCertificateInfo: undefined,
            selectedCertificateImage: undefined,
            selectedCertificateCurriculum: undefined,
            holderCertificates: undefined,
            searchCertificateKey: null,
            sortNodeCertsColumn: undefined,
            sortNodeCertsType: undefined,
            showModal: undefined,
            modalData: undefined,
            modalBackdrop: 'static',
            loading: true,
            activePage: 1,
            totalCertificates: 0
        };
        this.NodeService = new NodeService();
    }
    // --------------------------------------------------------------------------------------------------
    async getData(page: number) {
        let certs: CertificateInfo[] = [];

        if (this.props.holderAddress == null)
            return;

        // Get the list with certificates
        try {
            let info = await this.NodeService.getHolderCertificates(this.props.holderAddress, page);
            this.setState({ totalCertificates: info.totalCount });
            certs = info.result;
        } catch (ex) {
            console.error(ex);
        }
        this.setState({ holderCertificates: certs, loading: false });
    }
    // --------------------------------------------------------------------------------------------------
    componentDidMount() {
        this.getData(1);
    }
    // --------------------------------------------------------------------------------------------------
    handleSearchCertificateChange = (value: string, event: SyntheticEvent<HTMLElement, Event>) => this.setState({ searchCertificateKey: value })
    // --------------------------------------------------------------------------------------------------
    handleCertificateRowSelect = async (value: any, checked: boolean) => {
        var qr = await generateQR(value);
        var certInfo = _.find(this.state.holderCertificates, x => x.address === value)
        var lines = await CrewmanService.getCertificateInfo(certInfo?.type)
        this.setState({
            selectedCertificateKey: value,
            selectedCertificateImage: qr,
            selectedCertificateCurriculum: lines,
            selectedCertificateInfo: _.find(this.state.holderCertificates, x => x.address === value)
        });
    }
    // --------------------------------------------------------------------------------------------------
    onSortCertsColumn = (sortColumn: string, sortType: "desc" | "asc") => {
        this.setState((s: HolderCertificatesState) => {
            if (s.holderCertificates != null) {
                s.sortNodeCertsColumn = sortColumn;
                s.sortNodeCertsType = sortType;
                s.holderCertificates = _.orderBy(this.state.holderCertificates, x => _.get(x, sortColumn), sortType);
            }
            return s;
        })
    }
    // --------------------------------------------------------------------------------------------------
    renderCertificatePanel() {
        if (this.state.selectedNodeKey == null ||
            this.state.selectedCertificateKey == null ||
            this.state.selectedCertificateInfo == null)
            return <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
            </Navbar>

        return <>
            <Navbar>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Certificate</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        {
                            (!this.state.selectedCertificateInfo.revoked) ? null :
                                <Nav.Item icon={<img src={revokeIcon} style={{ width: 25, height: 25 }} />} > Revoked</Nav.Item>
                        }
                    </Nav>
                </Navbar.Body>
            </Navbar>

            <div style={{ width: "100%", height: this.props.height - 56 - 20, overflow: "auto" }}>
                <Certificate
                    width="100%"
                    height={Math.round(window.innerHeight * 90 / 100) - 250}
                    curriculumSummary={this.state.selectedCertificateCurriculum}
                    certInfo={this.state.selectedCertificateInfo}
                    img={this.state.selectedCertificateImage}
                />
            </div>

        </>
    }
    // --------------------------------------------------------------------------------------------------

    handleSelect = (eventKey: number) => {
        this.setState({ activePage: eventKey })
        this.getData(eventKey);
    }
    // --------------------------------------------------------------------------------------------------
    renderCAPanel() {

        // In loading showing placer for animation waiting
        if (this.state.holderCertificates == null)
            return <Panel bordered style={{ padding: 10, margin: 20, background: "white" }}>
                <Placeholder.Paragraph style={{ margin: 30 }} rows={5} graph="image" active />
            </Panel >

        // Filter certificates searching title/holder/serial_number
        let data = this.state.holderCertificates;
        if (this.state.searchCertificateKey != null) {
            let searchStr = new RegExp(this.state.searchCertificateKey, 'i');
            data = _.filter(data, d => {
                if ((d.title?.search(searchStr) ?? -1) > -1)
                    return true;
                if ((d.holder?.search(searchStr) ?? -1) > -1)
                    return true;
                return false;
            });
        }

        let holderInfo = {
            holderAddress: this.props.holderAddress,
            holderName: this.props.holderInfo?.middleName !== null ? `${this.props.holderInfo?.firstName} ${this.props.holderInfo?.middleName} ${this.props.holderInfo?.lastName}` : `${this.props.holderInfo?.firstName} ${this.props.holderInfo?.lastName}`,
            holderEmail: this.props.holderInfo?.email
        };

        return <>
            <Navbar appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Holder</div>
                </Navbar.Header>
            </Navbar>

            <Form formValue={holderInfo} layout="horizontal" style={{ margin: 10 }}>
                <FormGroup>
                    <ControlLabel>Holder</ControlLabel>
                    <FormControl name="holderAddress" plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl name="holderName" plaintext={true} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl name="holderEmail" plaintext={true} />
                </FormGroup>
            </Form>


            <Panel key="certificate-table" shaded bodyFill style={{ margin: 20 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Certificates</div>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight >
                            <Input style={{ width: 300, margin: 10 }} placeholder="Search..." onChange={this.handleSearchCertificateChange} />
                        </Nav>
                    </Navbar.Body>
                </Navbar>

                <Table height={500} rowHeight={46} data={data}
                    sortColumn={this.state.sortNodeCertsColumn}
                    sortType={this.state.sortNodeCertsType}
                    onSortColumn={this.onSortCertsColumn}>
                    <Table.Column width={50} align="center" >
                        <Table.HeaderCell></Table.HeaderCell>
                        <RadioCell
                            dataKey="address"
                            selectedKey={this.state.selectedCertificateKey}
                            onChange={this.handleCertificateRowSelect}
                        />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.Cell dataKey="title" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>CA</Table.HeaderCell>
                        <Table.Cell dataKey="certificate_authority" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Revoked</Table.HeaderCell>
                        <DisabledCell
                            dataKey="revoked"
                        />
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center" >
                        <Table.HeaderCell>Valid</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CertificateInfo) => {
                                return (<div>
                                    {!rowData.valid ? <Icon
                                        style={{ color: 'red' }}
                                        icon='minus-circle'
                                    /> : <Icon
                                        style={{ color: 'green' }}
                                        icon='check-circle'
                                    />}
                                </div>)

                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    maxButtons={6}
                    size="sm"
                    boundaryLinks={true}
                    pages={Math.ceil(this.state.totalCertificates / 10)}
                    activePage={this.state.activePage}
                    onSelect={this.handleSelect}
                />
            </Panel>
        </>
    }
    // --------------------------------------------------------------------------------------------------
    modalRevokeCertificate = async () => {
        if (this.state.selectedCertificateKey == null)
            return;
        this.setState({ showModal: CAModalModes.Processing });
        let response = await this.NodeService.revokeCertificate(this.state.selectedCertificateKey);
        if (response.code !== 0)
            this.setState({ showModal: CAModalModes.Error, modalData: { message: response.message.reason } })
        else
            this.closeModal();
        this.getData(1);
    }
    // --------------------------------------------------------------------------------------------------
    closeModal = () => this.setState({ showModal: undefined })
    handleModalDataChange = (value: any) => this.setState({ modalData: value });
    // --------------------------------------------------------------------------------------------------
    renderModals() {

        switch (this.state.showModal) {
            // --------------------------------
            case CAModalModes.Processing:
                return <>
                    <Modal.Header>
                        <Modal.Title>Processing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center' }}>
                            <Loader size="md" />
                        </div>
                    </Modal.Body>
                </>
            // --------------------------------
            case CAModalModes.Error: {
                let data: ModalError = this.state.modalData as ModalError;
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="warning"
                                size="3x"
                                style={{
                                    color: '#f04f43',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                {data.message}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} appearance="subtle">Close</Button>
                    </Modal.Footer>
                </>
            }
            // --------------------------------
            case CAModalModes.CertRevoke: {
                return <>
                    <Modal.Body>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Icon
                                icon="remind"
                                size="3x"
                                style={{
                                    color: '#ffb300',
                                    paddingRight: 10
                                }}
                            />
                            <div>
                                Revoke the certificate to {this.state.selectedCertificateKey}.
                                <br />
                                Are you sure you want to proceed ?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.modalRevokeCertificate} appearance="primary">Yes</Button>
                        <Button onClick={this.closeModal} appearance="subtle">No</Button>
                    </Modal.Footer>
                </>
            }
        }

        return null;
    }
    // --------------------------------------------------------------------------------------------------
    render() {
        if (this.state.loading)
            return <div style={{ textAlign: 'center' }}>
                <Loader size="md" />
            </div>

        return <div style={{ display: "flex" }} className="ca">
            <Panel shaded bodyFill style={{ flex: 1, height: this.props.height - 20, margin: 10, overflow: "auto" }}>
                {this.renderCAPanel()}
            </Panel>

            <Panel className="ca-certificate" shaded bodyFill style={{ flex: 1, margin: 10 }}>
                {this.renderCertificatePanel()}
            </Panel>

            <Modal backdrop={this.state.modalBackdrop} show={this.state.showModal != null} onHide={this.closeModal}>
                {this.renderModals()}
            </Modal>
        </div>
    }
}
// =======================================================================================================