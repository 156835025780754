
// ------------------------------------------------------------------------------------------------------
export interface CANodeInfo {
    ca: string;
    createRights: boolean;
    certs: CertificateInfo[];
    certsLength: number;
    balance: number;
    name: string;
}

export interface CertificatesInfo {
    result: CertificateInfo[];
    totalCount: number;
}

export interface AddCA {
    address: string;
    email: string;
    organization: string;
    name: string;
}
// ------------------------------------------------------------------------------------------------------
export interface CertificateInfo {
    holder: string;
    title: string;
    valid: boolean;
    extra: string | CertificateExtra;
    address: string;
    type: string;
    certificate_authority: string;
    governing_body: string;
    revoked: boolean;
}

export interface BulkCertificateInfo {
    certificate: CertificateInfo,
    holders: CrewDbUser[]
}
// ------------------------------------------------------------------------------------------------------
export interface CertificateExtra {
    // MSC, B.P. Marine Academy
    date_of_issuance: string | Date;
    date_of_expiration: string | Date;
    date_of_course_start: string | Date;
    date_of_course_end: string | Date;
    place_of_issuance: string;
    serial_number: string;
    course_instructor?: string;
    dob?: string;
    indosNo?: string;
    indos_certificate_no?: string;
    indos_date_of?: string;
    holder_username?: string;
    passportId?: string;
    cdcNo?: string;
    grade?: string;
    grade_no?: string;
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    crewId: string;
    rankCode: string;
    sessionId: string;
    centerName: string;
    issuedByName: string;
}
// ------------------------------------------------------------------------------------------------------
export enum CertificateType {
    MSCBasic = "MSC Basic",
    BPMarineAcademy = "B.P. Marine Academy",
    MEPC = "MEPC Course",
    HAZMAT = "Hazardous Material Training"
}
// ------------------------------------------------------------------------------------------------------
export type CertificateInfoForm = CertificateInfo & CertificateExtra;
// ------------------------------------------------------------------------------------------------------
export interface HolderInfo {
    email: string,
    firstName: string,
    lastName: string,
    middleName: string;
    address: string,
    date_of_birth: string | Date;
    date_of_verification: Date | undefined;
    verified: boolean
}

export interface CrewDbUser {
    email: string;
    firstName: string;
    address: string;
    lastName: string;
    middleName: string;
    dob: string;
    indosNo: string;
    passportId: string;
    cdcNo: string;
    crewId: string
    alreadyIssued: boolean
}

export interface CrewDbUserMV {
    label: string;
    value: CrewDbUser;
}
// ------------------------------------------------------------------------------------------------------



export enum Roles {
    GBAdministrator = "GBAdministrator",
    CAOWner = "CAOwner",
    CAAdmin = "CAUser",
    NFManager = "NFManager",
    IntCROfficer = "IntCROfficer"

}

export interface SearchCertificateCriteria {
    name: string;
    email: string;
    fromDate: Date | undefined;
    toDate: Date | undefined;
    sessionId: string;
    crewId: string;
    certType: string;
}

export interface CurriculumSummary {
    certInfo: string;
    items: Array<string>
}

export interface TrainingCenter {
    centerId: string;
    centerName: string;
}

export interface TrainingCenterMV {
    label: string;
    value: TrainingCenter;
}

export interface Training {
    trainingId: string;
    trainingName: string;
    expire: string;
    certName: string;
    certInfo: string;
}
export interface TrainingMV {
    label: string;
    value: Training
}

export interface TokenInfo {
    id: number;
    issuedTo: string;
    email?: string;
    phone?: string;
    validDate: Date | undefined;
    revoked?: boolean
    isValid?: boolean;
}

export interface InternalOfficer {
    name: string;
    email: string;
}

