import _ from 'lodash';
import * as React from 'react';
import { format } from 'date-fns'
import { Button, ButtonToolbar, Checkbox, ControlLabel, DatePicker, DateRangePicker, Form, FormControl, FormGroup, Icon, Input, Loader, Modal, Nav, Navbar, Pagination, Schema, SelectPicker, Table } from 'rsuite';
import { CrewmanService } from '../services/CrewmanService';
import { CertificateExtra, CertificateInfo, CrewDbUser, Training, TrainingMV } from '../services/ModelView';
import { NodeService } from '../services/NodeService';



// =======================================================================================================

// ------------------------------------------------------------------------------------------------------
export interface AddCertificateProps {
    ca: string;
    centerName: string;
    onSubmitComplete: (success: boolean, ErrMessage: string) => void;
}
// ------------------------------------------------------------------------------------------------------
export interface Session {
    name: string;
    sessionId: string;
    centerId: string;
    trainingId: string;
    placeOfIssuance: string;
    startCourseDate: string;
    endCourseDate: string;
    expirationDate: string;
    instructor: string
    seafarers: CrewDbUser[]
}

export interface SessionMV {
    label: string;
    value: Session;
}
// ------------------------------------------------------------------------------------------------------
export interface AddCertificateState {
    trainings: TrainingMV[] | undefined;
    selectedTrainingId: Training | undefined;
    selectedSessionStartDate: Date | undefined;
    selectedSessionEndDate: Date | undefined;
    trainingSessions: SessionMV[] | undefined;
    selectedTrainingSession: Session | undefined;
    formValue: CreateCertificate;
    formError: any;
    selectedCourse: Session | null;
    processing: boolean;
    errorMessage: string | undefined;
    showParticipants: boolean;

    //seafarers modal
    selectedHolders: CrewDbUser[];
    modalSearchResultHolders: CrewDbUser[];
    modalSelectedHolders: CrewDbUser[];
    searchSelectHolderKey: string | null;
    filteringHolders: boolean;
    holdersPage: number;
    showSeafarersModal: boolean;

    searchingSessions: boolean;
    notavailablesessions: boolean;
}
// ------------------------------------------------------------------------------------------------------
export interface CreateCertificate {
    title: string;
    type: string;
    certificate_authority: string;
    date_of_issuance: Date | undefined;
    date_of_expiration: undefined | Date;
    date_of_course_start: undefined | Date;
    date_of_course_end: undefined | Date;
    place_of_issuance: string;
    course_instructor?: string;
    sessionId: string;
    grade?: string;
    centerName: string;
}

// ------------------------------------------------------------------------------------------------------
const { StringType, DateType } = Schema.Types;
// ------------------------------------------------------------------------------------------------------
const model = Schema.Model({
    title: StringType().isRequired('This field is required'),
    place_of_issuance: StringType().isRequired('This field is required'),
    date_of_issuance: DateType().isRequired('This field is required').addRule((value, data) => {
        var _startDate = new Date(data.date_of_course_start);
        var startDate = new Date(_startDate.toDateString());
        if (startDate !== undefined && value < startDate)
            return false;
        return true
    }, 'Issuance date should be after Session starting date')
        .addRule((value, data) => {
            var _endDate = new Date(data.date_of_course_start);
            var endDate = new Date(_endDate.toDateString())
            if (endDate !== undefined && value < endDate)
                return false;
            return true
        }, 'Issuance date should be after Session ending date')
        .addRule((value, data) => {
            if (data.date_of_expiration === null || data.date_of_expiration === undefined || data.date_of_expiration.length === 0)
                return true;
            var _expiryDate = new Date(data.date_of_expiration);
            var expiryDate = new Date(_expiryDate.toDateString());
            if (expiryDate !== undefined && value >= expiryDate)
                return false;
            return true
        }, 'Issuance date should be less than expiry Date'),
});
// ------------------------------------------------------------------------------------------------------

const Places: Map<string, string> = new Map([
    ["MSCCSUA", "Odessa"],
    ["MSCCSIN", "Mumbai"],
    ["MSCSMCY", "Limassol"],
    ["MSCGVA", "Genevi"],
    ["MSCCSMM", "Yangon, Myanmar"],
    ["MSCSMSI","Manila"]
]);

// =======================================================================================================
export class AddCertificateForm extends React.Component<AddCertificateProps, AddCertificateState> {
    // ------------------------------------------------------------------------------------------------------
    private NodeService: NodeService;
    form: any;
    // ------------------------------------------------------------------------------------------------------

    constructor(props: any) {
        super(props);
        this.state =
        {
            formValue:
            {
                title: '',
                type: '',
                certificate_authority: '',
                date_of_issuance: undefined,
                date_of_expiration: undefined,
                date_of_course_start: undefined,
                date_of_course_end: undefined,
                place_of_issuance: '',
                sessionId: '',
                centerName: ''
            },
            formError: {},
            selectedCourse: null,
            processing: false,
            errorMessage: undefined,
            showParticipants: false,

            //seafarers modal
            selectedHolders: [],
            modalSearchResultHolders: [],
            modalSelectedHolders: [],
            searchSelectHolderKey: null,
            filteringHolders: false,
            holdersPage: 1,
            showSeafarersModal: false,

            //trainings
            trainings: undefined,
            selectedTrainingId: undefined,
            selectedSessionStartDate: undefined,
            selectedSessionEndDate: new Date(),
            trainingSessions: [],
            selectedTrainingSession: undefined,

            //
            searchingSessions: false,
            notavailablesessions: false,

        }
        this.NodeService = new NodeService()
    }
    // ------------------------------------------------------------------------------------------------------

    async componentDidMount() {
        var trainings = await CrewmanService.getTrainings();
        this.setState({ trainings: trainings })
        var date = new Date();
        var last = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
        this.setState({ selectedSessionStartDate: last })
    }
    // ------------------------------------------------------------------------------------------------------

    async searchSessions(centerName: string, training: Training, dateFrom: Date | undefined, dateTo: Date | undefined) {
        if (training === undefined || training === null) {
            alert("Training is not selected")
            return;
        }

        if (centerName === undefined || centerName === null || centerName.length === 0) {
            alert("Training center is not defined")
            return;
        }
        if (training !== undefined && dateFrom !== undefined && dateTo !== undefined) {
            this.setState({ searchingSessions: true, notavailablesessions: false })

            var from = format(dateFrom, "YYYY-MM-DD").split('T')[0]
            var to = format(dateTo, "YYYY-MM-DD").split('T')[0]

            var sessions = await this.NodeService.getSesssions(centerName, training.trainingId, from, to);
            if (sessions !== undefined && sessions.length > 0) {
                var trSessions = new Array<SessionMV>();
                sessions.forEach((element: Session) => {
                    element.name = training.trainingName;
                    var placeOfIssuance = undefined;
                    if (centerName !== undefined)
                        placeOfIssuance = Places.get(centerName);
                    if (placeOfIssuance !== undefined)
                        element.placeOfIssuance = placeOfIssuance;
                    else
                        element.placeOfIssuance = centerName;
                    if (training.expire !== null && training.expire !== undefined && training.expire.length > 0) {
                        var days = new Number(training.expire);
                        let date = new Date(element.startCourseDate);
                        date.setDate(date.getDate() + days.valueOf());
                        element.expirationDate = date.toISOString().split('T')[0];
                    }
                    var mv: SessionMV =
                    {
                        label: "Session: " + element.sessionId + ", Start Date: " + element.startCourseDate.split(' ')[0],
                        value: element
                    }
                    trSessions.push(mv);
                });

                var event = trSessions[0].value;
                var formdata: CreateCertificate = { ...this.state.formValue };
                _.set(formdata, "type", event.trainingId);
                _.set(formdata, "title", event.name);
                _.set(formdata, "place_of_issuance", event.placeOfIssuance);
                _.set(formdata, "date_of_course_start", event.startCourseDate);
                _.set(formdata, "date_of_course_end", event.endCourseDate);
                _.set(formdata, "course_instructor", event.instructor);
                _.set(formdata, "date_of_expiration", event.expirationDate);
                _.set(formdata, "sessionId", event.sessionId);
                _.set(formdata, "centerName", centerName);
                this.setState({ searchingSessions: false, trainingSessions: trSessions, selectedCourse: event, formValue: formdata, selectedHolders: event.seafarers })
            }
            else {
                this.setState({ notavailablesessions: true, searchingSessions: false });
            }
        }
    }
    // ------------------------------------------------------------------------------------------------------

    handleDates = (value: Array<Date>) => {
        //handle DateRsange
        this.setState({ selectedSessionStartDate: value[0] as Date })
        this.setState({ selectedSessionEndDate: value[1] as Date })
    }
    // ------------------------------------------------------------------------------------------------------

    selectTraining = (event: Training) => {
        if (event !== null && event !== undefined) {
            console.log("Training Changed")
            this.setState({ selectedTrainingId: event })
        }
    }
    // ------------------------------------------------------------------------------------------------------

    selectCertType = (event: Session) => {
        if (event !== null && event !== undefined) {
            console.error("Session Changed")
            var formdata: CreateCertificate = { ...this.state.formValue };
            _.set(formdata, "type", event.trainingId);
            _.set(formdata, "title", event.name);
            _.set(formdata, "place_of_issuance", event.placeOfIssuance);
            _.set(formdata, "date_of_course_start", event.startCourseDate);
            _.set(formdata, "date_of_course_end", event.endCourseDate);
            _.set(formdata, "course_instructor", event.instructor);
            _.set(formdata, "date_of_expiration", event.expirationDate);
            _.set(formdata, "sessionId", event.sessionId);
            this.setState({ formValue: formdata, selectedCourse: event, selectedHolders: event.seafarers });
        }
        else {
            this.setState({ selectedCourse: null, selectedHolders: [] })
        }
    }
    // ------------------------------------------------------------------------------------------------------
    addCertificate = async () => {
        const { formValue } = this.state;
        //check form errors
        console.log(this.state.formValue)
        //check form errors
        if (!this.form.check()) {
            console.error(this.form.getFormError());
            return;
        }
        if (this.state.selectedHolders == null || this.state.selectedHolders == undefined || this.state.selectedHolders.length == 0)
            return;
        this.setState({ processing: true })
        // extract extra field 
        let extra: Partial<CertificateExtra> = {};

        (extra as any)["place_of_issuance"] = formValue.place_of_issuance;
        (extra as any)["date_of_issuance"] = formValue.date_of_issuance;
        (extra as any)["course_instructor"] = formValue.course_instructor;
        (extra as any)["sessionId"] = formValue.sessionId;
        (extra as any)["date_of_course_start"] = formValue.date_of_course_start;
        (extra as any)["date_of_course_end"] = formValue.date_of_course_end;
        (extra as any)["date_of_expiration"] = formValue.date_of_expiration;
        (extra as any)["centerName"] = formValue.centerName;

        // make to string the extra fields
        var extra_json = JSON.stringify(extra);
        var cert: CertificateInfo =
        {
            type: formValue.type,
            title: formValue.title,
            certificate_authority: this.props.ca,
            holder: '',
            valid: true,
            revoked: false,
            address: '',
            governing_body: '',
            extra: extra_json
        }
        var newHolders = this.state.selectedHolders.filter(h => h.alreadyIssued == false);
        try {
            let response = await this.NodeService.caCreateCertificate(cert, newHolders);
            this.setState({ processing: false })
            if (response.code !== 0)
                this.setState({ errorMessage: "Failed to add the certificate. " + (response.message.reason ? response.message.reason : "") });
            else {
                this.props.onSubmitComplete(true, "")

            }
        } catch (ex) {
            console.error(ex);
            this.setState({ processing: false })
            this.setState({ errorMessage: "Failed to add the certificate. " });
        }
    }
    // ------------------------------------------------------------------------------------------------------

    changeHoldersPage = async (eventKey: number) => {
        this.setState({ holdersPage: eventKey, filteringHolders: true })
        await this.getHolders('', eventKey)
        this.setState({ filteringHolders: false })
    }

    // -------------------------------------------------------------------------------------------------- 
    render() {
        const { formValue } = this.state;

        return (
            <div>
                <Form style={{ width: "100%" }}
                    layout="horizontal"
                    ref={(ref: any) => (this.form = ref)}
                    onChange={(formValue) => {
                        this.setState({ formValue: formValue as CreateCertificate });
                    }}
                    onCheck={formError => {
                        this.setState({ formError });
                    }}
                    formValue={formValue}
                    model={model}
                >
                    {this.state.trainingSessions === undefined || this.state.trainingSessions.length == 0 ? <div>
                        <FormGroup>
                            <ControlLabel>Training</ControlLabel>
                            <SelectPicker onSelect={this.selectTraining} data={this.state.trainings!}></SelectPicker>
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>Sessions Date Range</ControlLabel>
                            <div className={'rs-form-control-wrapper'}>
                                {/* <DatePicker oneTap style={{ minWidth: "150px", float: "left", margin: 0 }} utcOffset={0} preventOverflow={true} onSelect={(v) => this.setState({ selectedSessionStartDate: v as Date })}></DatePicker> */}
                                <DateRangePicker value={[this.state.selectedSessionStartDate, this.state.selectedSessionEndDate]} style={{ minWidth: "150px", float: "left", margin: 0 }} utcOffset={0} preventOverflow={true} onOk={(v) => this.handleDates(v as Array<Date>)}></DateRangePicker>

                            </div>

                        </FormGroup>
                        {this.state.notavailablesessions ? <div style={{ color: "red", marginBottom: 10, textAlign: "center" }}>
                            Cannot find {this.state.selectedTrainingId?.trainingName}  sessions in the selected period
                        </div> : null}
                        <div style={{ textAlign: "center" }}>
                            {this.state.searchingSessions ? <Loader></Loader> :
                                <Button appearance="primary" onClick={(c) => this.searchSessions(this.props.centerName, this.state.selectedTrainingId!, this.state.selectedSessionStartDate, this.state.selectedSessionEndDate)}>
                                    Search Session
                                </Button>}
                        </div>
                    </div> : null}

                    {this.state.trainingSessions !== undefined && this.state.trainingSessions.length > 0 ?
                        <div>
                            <FormGroup>
                                <ControlLabel>Session</ControlLabel>
                                <SelectPicker defaultValue={this.state.selectedCourse} onChange={this.selectCertType} data={this.state.trainingSessions!}></SelectPicker>
                            </FormGroup>

                            {this.isTitleVisible() ? <FormGroup>
                                <ControlLabel>Title</ControlLabel>
                                <FormControl style={{ color: "black" }} disabled name="title" />
                            </FormGroup> : null}

                            {this.isPlaceOfIssuanceVisible() ? <FormGroup>
                                <ControlLabel>Place of Issuance</ControlLabel>
                                <FormControl style={{ color: "black" }} disabled name="place_of_issuance" />
                            </FormGroup> : null}

                            {this.isStartDateVisible() ? <FormGroup>
                                <ControlLabel>Start Course</ControlLabel>
                                <FormControl disabled style={{ color: "black" }} name="date_of_course_start" />
                            </FormGroup> : null}

                            {this.isEndDateVisible() ? <FormGroup>
                                <ControlLabel>End Course</ControlLabel>
                                <FormControl style={{ color: "black" }} disabled name="date_of_course_end" />
                            </FormGroup> : null}

                            {this.isExpiryDateVisible() ? <FormGroup>
                                <ControlLabel>Expiry Date</ControlLabel>
                                <FormControl style={{ color: "black" }} disabled name="date_of_expiration" />
                            </FormGroup> : null}

                            {this.isCourseSelected() ? <FormGroup>
                                <ControlLabel>Date of Issuance</ControlLabel>
                                <FormControl style={{ color: "black" }} name="date_of_issuance" accepter={DatePicker} oneTap block />
                            </FormGroup> : null}

                            {this.isCourseSelected() ? <FormGroup>
                                <ControlLabel>Instructor</ControlLabel>
                                <FormControl style={{ color: "black" }} name="course_instructor" disabled />
                            </FormGroup> : null}
                            {this.state.selectedHolders !== undefined && this.state.selectedHolders.length > 0 ? <div style={{ textAlign: "center" }}> <Button style={{ marginBottom: 20 }} appearance="primary" onClick={(c) => this.setState({ showParticipants: true })}>Show Eligible Seafarers</Button> </div> : null}
                            {this.state.selectedHolders !== undefined && this.state.selectedHolders.length > 0 ? <div>{this.renderHolders()}</div> : null}

                            <ButtonToolbar style={{ float: "right" }}>
                                {this.state.processing ? <Loader></Loader> : <Button onClick={this.addCertificate} on appearance="primary" type="submit">
                                    Add
                                </Button>}
                            </ButtonToolbar>
                        </div> : null}
                </Form>


                {(this.state.errorMessage != null) ?
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <Icon
                            icon="warning"
                            size="3x"
                            style={{
                                color: '#f04f43',
                                paddingRight: 10
                            }}
                        />
                        <div>
                            {this.state.errorMessage}
                        </div>
                    </div> : null}
            </div>
        );
    }
    // ------------------------------------------------------------------------------------------------------

    isTitleVisible() {
        return this.isCourseSelected();
    }
    // ------------------------------------------------------------------------------------------------------

    isPlaceOfIssuanceVisible() {
        return this.isCourseSelected()
    }
    // ------------------------------------------------------------------------------------------------------

    isStartDateVisible() {
        return this.isCourseSelected();
    }
    // ------------------------------------------------------------------------------------------------------

    isEndDateVisible() {
        return this.isCourseSelected();
    }
    // ------------------------------------------------------------------------------------------------------
    isCourseSelected() { return this.state.selectedCourse !== null && this.state.selectedCourse !== undefined }
    // ------------------------------------------------------------------------------------------------------

    isExpiryDateVisible() {
        return this.isCourseSelected() && this.state.selectedCourse?.expirationDate !== null && this.state.selectedCourse?.expirationDate !== undefined
    }

    // ------------------------------------------------------------------------------------------------------
    removeHolder(item: CrewDbUser): void {
        var tmp = this.state.selectedHolders;
        _.remove(tmp, item);
        this.setState({ selectedHolders: tmp })
    }
    // ------------------------------------------------------------------------------------------------------

    renderHolders() {

        return <Modal style={{ marginTop: 100 }} show={this.state.showParticipants} onHide={() => this.setState({ showParticipants: false })}>
            <Modal.Header>
                <Modal.Title>Eligible Seafarers</Modal.Title>
            </Modal.Header><Table style={{ marginTop: 20 }}
                virtualized
                data={this.state.selectedHolders}>

                <Table.Column flexGrow={1} align="center">
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.Cell >
                        {(rowData: CrewDbUser) => {
                            return <Checkbox style={{ marginTop: -5 }} checked={rowData.alreadyIssued} />
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                    <Table.HeaderCell>CrewId</Table.HeaderCell>
                    <Table.Cell dataKey="crewId" />
                </Table.Column>
                <Table.Column flexGrow={2} align="center">
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.Cell >
                        {(rowData: CrewDbUser) => {
                            return <div>
                                {rowData.firstName} {rowData.middleName} {rowData.lastName}
                            </div>
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={3} align="center">
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.Cell dataKey="email" />
                </Table.Column>
            </Table>
        </Modal>
    }


    //Seafarers utilities
    // ------------------------------------------------------------------------------------------------------

    async getHolders(searchString: string, page: number) {

        var users = await this.NodeService.getDbHolders(searchString, page);
        return users;
    }
    // ------------------------------------------------------------------------------------------------------

    modalSelectHolder = async () => {

        this.setState({ showSeafarersModal: true })
        var seafarers = await this.NodeService.getDbHolders('', 1);
        this.setState({ modalSearchResultHolders: seafarers })
    }
    // ------------------------------------------------------------------------------------------------------

    async filterHolders(searchSelectHolderKey: string) {
        if (!this.state.filteringHolders) {
            this.setState({ filteringHolders: true })
            var users = await this.NodeService.getDbHolders(searchSelectHolderKey, 1);
            this.setState({ modalSearchResultHolders: users, filteringHolders: false, searchSelectHolderKey: null })
        }
    }
    // ------------------------------------------------------------------------------------------------------

    updateSelectedHolders(value: any, checked: boolean, event: React.SyntheticEvent<HTMLInputElement, Event>, key: CrewDbUser) {
        var currentState = this.state.modalSelectedHolders;
        if (checked)
            currentState.push(key)
        else
            _.remove(currentState, key);
        this.setState({ modalSelectedHolders: currentState })
    }
    // ------------------------------------------------------------------------------------------------------

    confirmHolders = () => {
        var existingHolders = this.state.selectedHolders;
        var conactResult = existingHolders.concat(this.state.modalSelectedHolders);
        this.setState({ showSeafarersModal: false, selectedHolders: conactResult, modalSearchResultHolders: [], modalSelectedHolders: [] })

    }
    // ------------------------------------------------------------------------------------------------------

    renderSeafarersModal() {
        var allData: CrewDbUser[] = this.state.modalSearchResultHolders;
        var b = this.state.selectedHolders;
        var data = allData.filter(function (objFromA) {
            return !b.find(function (objFromB) {
                return objFromA.crewId === objFromB.crewId
            })
        });

        if (this.state.searchSelectHolderKey !== null && this.state.searchSelectHolderKey !== undefined && (this.state.searchSelectHolderKey.length > 5 || this.state.searchSelectHolderKey.length === 0)) {
            this.filterHolders(this.state.searchSelectHolderKey);
        }
        if (!_.isArray(data))
            data = [];
        return <>
            <Navbar>
                <Navbar.Header>
                    <div style={{
                        width: "100%",
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Holders</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        <Input disabled={this.state.filteringHolders} style={{ width: 300, margin: 10 }} placeholder="Search..." onChange={(searchSelectHolderKey) => this.setState({ searchSelectHolderKey })} />
                    </Nav>
                </Navbar.Body>
            </Navbar>

            <Modal.Body style={{ width: "100%" }}>
                <Table loading={this.state.filteringHolders} virtualized height={500} rowHeight={46} data={data}>

                    <Table.Column align="center" >
                        <Table.HeaderCell>Select</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CrewDbUser) => {
                                return (
                                    <Checkbox onChange={(value, checked, event) => { this.updateSelectedHolders(value, checked, event, rowData); }

                                    } />
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>eMail</Table.HeaderCell>
                        <Table.Cell dataKey="email" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>First Name</Table.HeaderCell>
                        <Table.Cell dataKey="firstName" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Last Name</Table.HeaderCell>
                        <Table.Cell dataKey="lastName" />
                    </Table.Column>
                </Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="md"
                    activePage={this.state.holdersPage}
                    pages={Math.ceil(this.state.modalSearchResultHolders.length / 100)}
                    onSelect={this.changeHoldersPage}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ margin: 10, float: "right" }} onClick={this.confirmHolders} appearance="primary">
                    Ok
                </Button>
            </Modal.Footer>
        </>
    }
    // ------------------------------------------------------------------------------------------------------
}
// =======================================================================================================
