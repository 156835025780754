import React from "react"
import _, { isEmpty, isNull } from "lodash";
import { format } from 'date-fns'

import {
    Form, FormGroup, ControlLabel,
    FormControl, IconButton, Icon,
    Checkbox,
} from "rsuite"

import {
    Page, Text, View, Document, Image,
    StyleSheet, PDFViewer, Font
} from '@react-pdf/renderer';

import CertificateLayoutOdessa from '../img/certlayout.png'
import CertificateLayoutMumbai from '../img/certlayout_mumbai.png'
import CertificateLayoutMyanmar from '../img/certlayout_myanmar.png'
import CertificateLayoutManila from '../img/certlayout_manila.png'
import CertificateLayout from '../img/certlayout_blank.png';

import {
    CertificateInfo, CertificateExtra,
    CertificateInfoForm,
    CurriculumSummary,
} from "../services/ModelView"
import QRCode from 'qrcode';



// =======================================================================================================
export interface FieldsMapping {
    formField: string;
    field: string;
    label: string;
    type: "string" | "number" | "boolean" | "datetime" | "hidden";
}

export const allCertsFields = [
    { formField: "extra.place_of_issuance", field: "place_of_issuance", label: "Place of Issuance", type: "string" },
    { formField: "extra.serial_number", field: "serial_number", label: "Serial Number", type: "hidden" },
    { formField: "extra.course_instructor", field: "course_instructor", label: "Course Instructor", type: "string" },
    { formField: "extra.sessionId", field: "sessionId", label: "Session Id", type: "string" },
    { formField: "extra.issuedByName", field: "issuedByName", label: "Issued By", type: "string" },
    { formField: "extra.date_of_issuance", field: "date_of_issuance", label: "Date of Issuance", type: "datetime" },
    { formField: "extra.date_of_expiration", field: "date_of_expiration", label: "Date of Expiration", type: "datetime" },
    { formField: "extra.date_of_course_start", field: "date_of_course_start", label: "Date of Course Start", type: "datetime" },
    { formField: "extra.date_of_course_end", field: "date_of_course_end", label: "Date of Course End", type: "datetime" },
    //{ formField: "extra.dob", field: "dob", label: "Date of Birth", type: "hidden" },
    { formField: "extra.indosNo", field: "indosNo", label: "INDoS No", type: "hidden" },
    { formField: "extra.passportId", field: "passportId", label: "Passport No", type: "hidden" },
    { formField: "extra.cdcNo", field: "cdcNo", label: "C.D.C. No", type: "hidden" },
    { formField: "extra.grade", field: "grade", label: "Grade", type: "string" },
    { formField: "extra.grade_no", field: "grade_no", label: "Grade No", type: "string" },
    { formField: "extra.crewId", field: "crewId", label: "Crew ID", type: "string" },
    { formField: "extra.rankCode", field: "rankCode", label: "Rank Code", type: "string" },
    { formField: "extra.centerName", field: "centerName", label: "Center Name", type: "string" }
]



// --------------------------------------------------------------------------------------------------

export function buildExtraFields(extra: any) {
    let items: Array<any> = [];
    try {
        allCertsFields.forEach(item => {
            if (extra[item.field] !== null && extra[item.field] !== undefined) {
                items.push(<FormGroup>
                    <ControlLabel>{item.label}</ControlLabel>
                    <FormControl name={item.formField} plaintext={true} />
                </FormGroup>)
            }
        });
    }
    catch (error) {
        console.error(error)
    }
    return items;
}

// ------------------------------------------------------------------------------------------------------
export interface CertificateProps {
    width: number | string;
    height: number | string;
    certInfo: Partial<CertificateInfo>;
    img?: string | undefined;
    curriculumSummary?: CurriculumSummary;
    showHolderInfo?: () => void;
    requestHolderChallenge?: () => void;
}
// ------------------------------------------------------------------------------------------------------

export function Certificate(props: CertificateProps) {
    let info: Partial<CertificateInfoForm> = _.clone(props.certInfo);
    let extra: any = {};
    if (info.extra != null) {
        try {
            extra = JSON.parse(info.extra as string);
            info.email = extra["email"]
            let fields = allCertsFields;
            _.forEach(fields, f => {
                var row = extra[f.field];
                if (f.field === "date_of_issuance" || f.field === "date_of_expiration" || f.field == "date_of_course_start" || f.field == "date_of_course_end") {
                    var date = new Date(row);
                    row = date.getFullYear().toString() + "-" +
                        (date.getMonth() + 1).toString() +
                        "-" + date.getDate()
                }
                (info as any)[f.formField] = row;
            });
        } catch (ex) {
            console.error(ex);
        }
    }

    return <div>
        <Form formValue={info} layout="horizontal" style={{ margin: 20 }}>
            <FormGroup>
                <ControlLabel>Type</ControlLabel>
                <FormControl style={{ padding: 10 }} name="type" plaintext={true} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Title</ControlLabel>
                <FormControl name="title" plaintext={true} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Holder Last/First Name</ControlLabel>
                <FormControl name="holderName" plaintext={true} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Holder Email</ControlLabel>
                <FormControl name="email" plaintext={true} />
            </FormGroup>
            {buildExtraFields(extra)}
            <FormGroup>
                <ControlLabel>Revoked</ControlLabel>
                <FormControl name="revoked" plaintext={true}
                    accepter={Checkbox as any}
                    checked={props.certInfo.revoked}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Address</ControlLabel>
                <FormControl name="address" plaintext={true} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Certificate Authority</ControlLabel>
                <FormControl name="certificate_authority" plaintext={true} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Holder</ControlLabel>
                <FormControl name="holder" plaintext={true} />
                {props.showHolderInfo != null ? <IconButton size="lg" icon={<Icon icon="info" />} onClick={props.showHolderInfo} /> : null}
            </FormGroup>
        </Form>

        <div style={{ padding: 10 }}>
            <CertificatePdf certInfo={info} img={props.img} curriculumSummary={props.curriculumSummary} />
        </div>


    </div>
}
// ------------------------------------------------------------------------------------------------------

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 5,
        paddingHorizontal: 5,
        paddingBottom: 5,
    },
    mainDoc:
    {
        position: "absolute",
        top: 60,
        paddingHorizontal: 50
    },
    banner: {
        flexDirection: 'row',
        marginBottom: 50
    },
    bannerTitle: {
        flexGrow: 1,
        width: 200
    },
    bannerImage: {
        width: 100,
        height: 100
    },
    text: {
        fontSize: 11,
        textAlign: 'justify',
        marginBottom: 5
    },
    text_split: {
        fontSize: 11,
        marginBottom: 5
    },
    inputvalues: {
        fontSize: 11,
        fontFamily: 'Roboto',
        fontWeight: "bold",
        marginBottom: 5
    },
    curriculumText:
    {
        fontSize: 9,
        textAlign: 'justify',
        marginBottom: 0
    }

});

Font.register({
    family: 'Roboto',
    //src: 'https://fonts.gstatic.com/s/opensans/v15/K88pR3goAWT7BTt32Z01m4X0hVgzZQUfRDuZrPvH3D8.woff2',
    fonts: [
        {
            src: "https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2"
        },
        {
            src: "https://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf",
            fontWeight: 'bold'
        }
    ]
});
// ------------------------------------------------------------------------------------------------------
export interface CertificatePdfProps {
    certInfo: Partial<CertificateInfo>;
    img: string | undefined;
    curriculumSummary: CurriculumSummary | null | undefined
}

export async function generateQR(value: string) {
    try {
        var res = await QRCode.toDataURL(value);
        return res;
    } catch (err) {
        console.error(err)
    }
}



export function CertificatePdf(props: CertificatePdfProps) {
    var extra: CertificateExtra | undefined = undefined;
    try {
        extra = JSON.parse(props.certInfo.extra as string) as CertificateExtra;
        extra.date_of_issuance = formatDate(extra.date_of_issuance.toString());
        extra.date_of_expiration = formatDate(extra.date_of_expiration.toString());
        extra.date_of_course_start = formatDate(extra.date_of_course_start.toString());
        extra.date_of_course_end = formatDate(extra.date_of_course_end.toString());

        console.error("EXTRA:", extra);
    }
    catch (error) { }
    return CertificatePdfTemplate(props, extra, props.img, props.curriculumSummary);
}

export function formatDate(date_s: string) {
    var date = new Date(date_s);
    var row = date.getFullYear().toString() + "-" +
        (date.getMonth() + 1).toString() +
        "-" + date.getDate()
    return row;

}
export function renderCurriculum(lines: Array<string>) {
    if (lines === null || lines === undefined || lines.length === 0)
        return;
    let items: Array<any> = [];
    lines.forEach((i) => {
        items.push(
            <View style={{ flexDirection: "row-reverse", justifyContent: "flex-end", marginLeft: 30 }}>
                <View>
                    <Text style={{ ...styles.curriculumText, marginRight: 20 }}>{typeof i != 'undefined' && i ? i : null}</Text>
                </View>
                <View>
                    <Text style={{ ...styles.curriculumText }}>{typeof i != 'undefined' && i ? '• ' : null}</Text>
                </View>
            </View>)
    });
    return items;
}

export function CertificatePdfTemplate(props: CertificatePdfProps, extra: CertificateExtra | undefined, img: any, curriculumLines: any) {


    return <PDFViewer style={{ width: "100%", height: 1000, borderStyle: "dotted", borderRadius: 20 }}>
        <Document>
            {SingleCertificatePage(props, extra, img, curriculumLines)}
        </Document>
    </PDFViewer>
}

export function SingleCertificatePage(props: CertificatePdfProps, extra: CertificateExtra | undefined, img: any, curriculumLines: any) {
    var name = extra?.middleName !== null ? `${extra?.firstName} ${extra?.middleName} ${extra?.lastName}` : `${extra.firstName} ${extra.lastName}`;
    var days = 0;
    if (extra != undefined) {
        var endDay = new Date(extra?.date_of_course_end);
        var startDay = new Date(extra?.date_of_course_start);
        days = (Number(endDay.getTime()) == Number(startDay.getTime())) ? 1 : Math.ceil((Number(endDay.getTime()) - Number(startDay.getTime())) / (1000 * 3600 * 24));
    }

    var endDate = "" + extra?.date_of_course_end;
    endDate = format(endDate, "YYYY-MM-DD").split('T')[0]
    var startDate = "" + extra?.date_of_course_start;
    startDate = format(startDate, "YYYY-MM-DD").split('T')[0]

    return <Page size="A4" style={styles.body} >

        <Image style={{ width: "100%", height: 830 }}

            src={extra?.place_of_issuance === "Odessa" ? CertificateLayoutOdessa : extra?.place_of_issuance === "Mumbai" ? CertificateLayoutMumbai : extra?.place_of_issuance.includes("Myanmar") ? CertificateLayoutMyanmar : extra?.place_of_issuance.includes("Manila") ? CertificateLayoutManila : CertificateLayout} />

        <View style={{ ...styles.mainDoc, flex: 1, flexDirection: "column", width: "100%" }}>

            <View style={{ height: 600 }}>
                <Text style={{ ...styles.text, marginLeft: 4 }}>Certificate Address</Text>

                <Image style={{ ...styles.bannerImage, textAlign: 'justify' }} source={img} />
                <Text style={{ ...styles.text, textAlign: "center", paddingTop: 50 }}>
                    This is to certify that
                </Text>

                {name === undefined || isEmpty(name) || isNull(name) ?
                    <Text style={{ ...styles.inputvalues, textAlign: "center", fontWeight: "bold" }}></Text> :
                    <Text style={{ ...styles.inputvalues, textAlign: "center", fontWeight: "bold" }}>{extra?.rankCode} {name}
                    </Text>}

                {(extra?.date_of_course_end || extra?.date_of_course_start) === undefined || days === undefined || isNaN(days) ?
                    <Text style={{ ...styles.text, textAlign: "center" }}></Text> :
                    <Text style={{ ...styles.text, textAlign: "center" }}>
                        Has successfully completed a <Text style={{ ...styles.text }}>{days}</Text>-day course in</Text>}

                {props.certInfo.title === undefined || isEmpty(props.certInfo.title) || isNull(props.certInfo.title) ?
                    <Text style={{ ...styles.text, fontSize: 13, textAlign: "center" }}></Text> :
                    <Text style={{ ...styles.text, fontSize: 13, textAlign: "center" }}>{props.certInfo.title}</Text>}

                {(extra?.date_of_course_end || extra?.date_of_course_start) === undefined ?
                    <Text style={{ ...styles.text, textAlign: "center" }}></Text> :
                    <Text style={{ ...styles.text, textAlign: "center" }}>
                        Held between {startDate} and {endDate}
                    </Text>}

                {curriculumLines?.certInfo === undefined || isEmpty(curriculumLines?.certInfo) || isNull(curriculumLines?.certInfo) ?
                    <Text style={{ ...styles.text, textAlign: "center", marginLeft: 20, marginRight: 20 }}></Text> :
                    <Text style={{ ...styles.text, textAlign: "center", marginLeft: 20, marginRight: 20 }}>{curriculumLines.certInfo}</Text>}

                {curriculumLines?.items === undefined || isEmpty(curriculumLines?.items) || isNull(curriculumLines?.items) ?
                    <Text style={{ ...styles.text, textAlign: "justify", marginLeft: 15 }}></Text> :
                    <Text style={{ ...styles.text, textAlign: "justify", marginLeft: 15 }}>
                        Covering the topics:</Text>}

                {curriculumLines?.items === undefined || isEmpty(curriculumLines?.items) || isNull(curriculumLines?.items) ?
                    <Text></Text> :
                    renderCurriculum(curriculumLines.items)}
            </View>

            <View style={{ height: 10 }}></View>

            <View style={{ height: 70, paddingBottom: 10 }}>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                    <View>
                        {extra?.date_of_issuance === undefined || isEmpty(extra?.date_of_issuance) || isNull(extra?.date_of_issuance) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 15 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 15 }}>Date of Issue: {extra.date_of_issuance?.toString()?.split('T')[0]}</Text>
                        }

                        {extra?.date_of_expiration === undefined || isEmpty(extra?.date_of_expiration) || isNull(extra?.date_of_expiration) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 31 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 31 }}>Valid Until: {extra.date_of_expiration}</Text>
                        }

                        {extra?.place_of_issuance === undefined || isEmpty(extra?.place_of_issuance) || isNull(extra?.place_of_issuance) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 10 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginLeft: 10 }}>Place of Issue: {extra.place_of_issuance}</Text>
                        }
                    </View>
                    <View>
                        {extra?.course_instructor === undefined || isEmpty(extra?.course_instructor) || isNull(extra?.course_instructor) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15 }}>Issued by:</Text>
                        }

                        {extra?.course_instructor === undefined || isEmpty(extra?.course_instructor) || isNull(extra?.course_instructor) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15, marginLeft: 20 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15, marginLeft: 20 }}>{extra.course_instructor}</Text>
                        }

                        {extra?.centerName === undefined || isEmpty(extra?.centerName) || isNull(extra?.centerName) ?
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15 }}></Text> :
                            <Text style={{ ...styles.text_split, flex: 1, justifyContent: "flex-end", marginRight: 15 }}>{extra.centerName}</Text>
                        }
                    </View>
                </View>
            </View>

        </View>
    </Page>
}

// ------------------------------------------------------------------------------------------------------
export function CertificatePdfBPMarineAcademy(props: CertificatePdfProps, extra: CertificateExtra) {
    return <PDFViewer style={{ width: "100%", height: 1000, borderStyle: "dotted", borderRadius: 20 }}>
        <Document>
            {/* <Page size="A4" style={styles.body}>
                <Text style={styles.header} fixed>
                    Certificate No. <Text style={styles.fields}>{extra.serial_number}</Text>
                </Text>
                <View style={styles.banner} >
                    <Image
                        style={styles.bannerImage}
                        src="https://scontent.fnic3-1.fna.fbcdn.net/v/t1.0-9/529658_321184931306112_1971238155_n.jpg?_nc_cat=104&_nc_sid=85a577&_nc_ohc=_sUs4MY3bT8AX93bnuR&_nc_ht=scontent.fnic3-1.fna&oh=0b534b9859a6e22e9de52523b8815b3a&oe=5F1B814D"
                    />
                    <View style={styles.bannerTitle}>
                        <Text style={styles.title}>B.P. Marine Academy</Text>
                        <Text style={styles.author}>5AI PODJA, PLOT 58, SEC. 11, C.B.D. BELAPUR, NAVI MUMBAI – 400 614</Text>
                        <Text style={styles.author}>INDOS No. 012 – TEL : 91-22-2757 4082 / 2756 5179 / 2756 2179 – FAX : 91-22-2756 1543</Text>
                        <Text style={styles.author}>E-mail : bpmarine.academy@gmail.com – Website: www.bpmarineacademy.in</Text>
                    </View>
                </View>
                <Text style={styles.text}>
                    THIS IS TO CERTIFY THAT <Text style={styles.fields}>{name}</Text>
                </Text>

                <Text style={styles.text}>
                    Date of Birth <Text style={styles.fields}>{extra.dob}</Text>
                </Text>


                <Text style={styles.text}>
                    Holder of Indian C.D.C. No. <Text style={styles.fields}>{extra.cdcNo}</Text> Passport No. <Text style={styles.fields}>{extra.passportId}</Text>
                </Text>

                <Text style={styles.text}>
                    Certificate of Competency/ Proficiency, (if any) Grade <Text style={styles.fields}>{extra.grade} </Text> No. <Text style={styles.fields}>{extra.grade_no}</Text>
                </Text>

                <Text style={styles.text}>
                    Indian National Database of Seafarers (INDoS) No.<Text style={styles.fields}>{extra.indosNo}</Text>
                </Text>

                <Text style={styles.text}>
                    holding Certificate No. <Text style={styles.fields}>{extra.indos_certificate_no}</Text> Dated: <Text style={styles.fields}>{extra.indos_date_of}</Text>
                </Text>

                <Text style={styles.text}>
                    issued by <Text style={styles.fields}> B.P. MARINE ACADEMY </Text> meeting the requirements of Reg. VI/4 Para 2 and Table A-VI/4-2 of the STCW Code has Completed: 
                </Text>

                <Text style={styles.certTitle}>{props.certInfo.title}</Text>


                <Text style={styles.text}>
                    On <Text style={styles.fields}> {extra.date_of_issuance} </Text> as approved by the Directorate Gereral of Shipping to meet the gaidance contained in Section B-4.1.1 Paragraphs 3 of the Maritime Labour Convention 2006.
                </Text>


                {(extra.date_of_issuance !== '0') ?
                    <Text style={styles.text}>
                        Date of Issue: <Text style={styles.fields}>{extra.date_of_issuance}</Text>
                    </Text>
                    : null}

                {(extra.date_of_expiration !== '0') ?
                    <Text style={styles.text}>
                        Date of Expiry: <Text style={styles.fields}>{extra.date_of_expiration}</Text>
                    </Text>
                    : null}

            </Page> */}
        </Document>
    </PDFViewer >
}


// ------------------------------------------------------------------------------------------------------